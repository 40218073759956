// components/VehicleRegistrationForm.tsx
import React from 'react';
import { ValidationError } from 'utils/types';

interface VehicleRegistrationFormProps {
  vehicleReg: string;
  setVehicleReg: (value: string) => void;
  validation: ValidationError;
  setValidation: (validation: ValidationError) => void;
  onSubmit: (vehicleReg: string) => void;
  isPending: boolean;
}

const VehicleRegistrationForm: React.FC<VehicleRegistrationFormProps> = ({
  vehicleReg,
  setVehicleReg,
  validation,
  setValidation,
  onSubmit,
  isPending,
}) => {
  const validateVehicleReg = (value: string): ValidationError => {
    if (!/^[a-zA-Z0-9]*$/.test(value)) {
      return {
        hasError: true,
        message: 'Only letters and numbers are allowed',
      };
    }
    if (value.length > 10) {
      return {
        hasError: true,
        message: 'Maximum 10 characters allowed',
      };
    }

    return {
      hasError: false,
      message: '',
    };
  };

  // Handle input change with validation
  const handleVehicleRegInput = (value: string) => {
    const checkValidation = validateVehicleReg(value);
    setValidation(checkValidation);

    if (!checkValidation.hasError) {
      setVehicleReg(value.toUpperCase());
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate before submission
    const checkValidation = validateVehicleReg(vehicleReg);
    if (checkValidation.hasError) {
      setValidation(checkValidation);
      return;
    }

    onSubmit(vehicleReg);
  };

  return (
    <>
      <p className="text-base text-gray-700 leading-relaxed">
        Enter your vehicle registration number to receive an OTP on your
        registered phone number.
      </p>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="vehicleReg"
            className="block text-sm font-medium text-gray-600"
          >
            Vehicle Registration Number
          </label>
          <input
            id="vehicleReg"
            name="vehicleReg"
            type="text"
            value={vehicleReg}
            onChange={(e) => handleVehicleRegInput(e.target.value)}
            required
            className={`w-full mt-1 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 uppercase ${
              validation.hasError ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="e.g., KAA123A"
          />
          {validation.hasError && (
            <p className="mt-1 text-sm text-red-500">{validation.message}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={isPending || validation.hasError}
          className="w-full py-2 px-4 bg-ntsaGreen text-white rounded-md hover:bg-blue-700 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isPending ? 'Sending OTP...' : 'Send OTP'}
        </button>
      </form>
    </>
  );
};

export default VehicleRegistrationForm;
