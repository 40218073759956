import React from 'react';
import { FileText } from 'lucide-react';
import Button from 'components/shared/Button/Button';

interface TableHeaderProps {
  onReportOpen: () => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ onReportOpen }) => (
  <div className="flex justify-between items-center">
    <h2 className="text-lg font-semibold text-gray-800">Transactions Table</h2>
    <div className="flex space-x-4">
      <Button
        label="Reports"
        color="black"
        backGroundColor="gray-100"
        hoverBackgroundColor="gray-200"
        hoverColor="black"
        Icon={FileText}
        onClick={onReportOpen}
      />
    </div>
  </div>
);
