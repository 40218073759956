import * as React from 'react';

import moment from 'moment';
import { getBackgroundColor, getFirstTwoLetters } from 'utils/Functions';
import { Column } from 'components/common/table';

export const getTableColumns = (): Column[] => [
  {
    header: 'Vehicle Image',
    accessorKey: 'imageUrl',
    thumbnail: {
      width: 50,
      height: 50,
    },
  },
  {
    header: 'Date Captured',
    accessorKey: 'dateCaptured',
    filterable: true,
    filterType: 'date',
    cell: (value: string) => moment(value).format('Do MMMM YYYY h:mm a'),
  },
  {
    header: 'Date (SMS) Processed',
    accessorKey: 'dateCreated',
    filterType: 'date',
    cell: (value: string) => moment(value).format('Do MMMM YYYY h:mm a'),
  },
  {
    header: 'Ticket No',
    accessorKey: 'ticketNumber',
    filterable: true,
    filterType: 'text',
  },
  {
    header: 'Vehicle Registration',
    accessorKey: 'vehicleRegistrationNumber',
    editable: true,
    filterable: true,
    filterType: 'text',
  },
  {
    header: 'Location',
    accessorKey: 'location',
    filterable: true,
    filterType: 'text',
  },

  {
    header: 'Sms status',
    accessorKey: 'smsStatus',
    filterable: true,
    filterType: 'select',
    filterOptions: [
      { value: 'S', label: 'Sent' },
      { value: 'P', label: 'Pending' },
      { value: 'F', label: 'Failed' },
    ],
    cell: (value: string) => (
      <span
        className={`${value === 'P' || value === null ? 'text-black' : 'text-white'} px-3 py-1 rounded-3xl justify-center flex items-center ${getBackgroundColor(value)}`}
      >
        {value === null
          ? 'No status'
          : value === 'S'
            ? 'Sent'
            : value === 'P'
              ? 'Pending'
              : 'Failed'}
      </span>
    ),
  },
  {
    header: 'Camera',
    accessorKey: 'violationId',
    filterable: true,
    filterType: 'select',
    filterOptions: [
      { value: 'GDS', label: 'ARH' },
      { value: 'PS', label: 'Vitronics' },
    ],
    cell: (value: string) => (
      <span>{getFirstTwoLetters(value, 2) === 'PS' ? 'Vitronics' : 'ARH'}</span>
    ),
  },
  {
    header: 'Speed Limit',
    accessorKey: 'speedLimit',
  },
  {
    header: 'Vehicle Speed',
    accessorKey: 'vehicleSpeed',
  },
];
