import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import { AddInfraction } from 'utils/types';

const processCorrectedPlate = async (data: AddInfraction) => {
  const response = await axiosInstance.post(
    '/fine/process_corrected_plate',
    data,
  );
  return response.data;
};

const useProcessCorrectedPlate = (): UseMutationResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Error,
  AddInfraction
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processCorrectedPlate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fine', 'fines'] });
    },
    onError: (error: Error) => {
      console.error('Error adding an infraction:', error.message);
    },
  });
};

export default useProcessCorrectedPlate;
