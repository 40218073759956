import Spinner from 'components/common/spinner';
import ViolationsTable from 'components/violations/ViolationTable';
import useGetOffences from 'hooks/offences/useGetOffences';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Violations = () => {
  const { data: offences, isLoading: offencesLoading } = useGetOffences();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page') || '1', 10),
  );

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1', 10);
    setCurrentPage(page);
  }, [searchParams]);
  const goToPage = (pageNumber: number) => {
    if (offences !== undefined && pageNumber > 0) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', pageNumber.toString());
      setSearchParams(newSearchParams);
    }
  };
  if (offencesLoading) return <Spinner />;
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <ViolationsTable
        data={offences ?? []}
        totalPages={3}
        goToPage={goToPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  );
};

export default Violations;
