import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MoveLeft } from 'lucide-react';

type Props = {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
};

const SingleViewLayout = ({ children, title, subTitle }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="container   lg:py-8 px-4">
      <div className="bg-white rounded-lg py-8 px-10 shadow-lg overflow-hidden">
        <div className="flex justify-between">
          <button
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-gray-100 bg-gray-100 rounded-full transition-colors"
          >
            <MoveLeft size={16} />
          </button>
        </div>
        <div className="border-b border-gray-200 px-6 py-4">
          <div className="flex items-center space-x-4">
            <div>
              <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
              <p className="text-sm text-gray-500">{subTitle}</p>
            </div>
          </div>
        </div>

        {children}
      </div>
    </div>
  );
};

export default SingleViewLayout;
