import { X, LogOut } from 'lucide-react';
import { SidebarItems } from './SideBarItems';
import { useSidebar } from 'context/SideBarContext';
import { useAuth } from 'context/AuthContext';
import React, { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import UserProfileBadge from './UserProfileBadge';
import MenuItem from './MenuItem';

const Sidebar = () => {
  const { isSidebarOpen, closeSidebar } = useSidebar();
  const { auth } = useAuth();
  const filteredSidebarItems = auth.includes('finance')
    ? SidebarItems
    : SidebarItems.filter((item) => item.title !== 'Finance');
  const { keycloak, initialized } = useKeycloak();

  const logout = useCallback(() => {
    keycloak?.logout({ redirectUri: `${window.location.origin}/auth/login` });
  }, [keycloak]);

  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={closeSidebar}
        />
      )}

      <aside
        className={`
          fixed top-0 left-0 z-30
          h-full w-64 bg-white shadow-lg
          transform transition-transform duration-300 ease-in-out
          lg:translate-x-0 lg:h-screen
          flex flex-col overflow-hidden
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <UserProfileBadge />
        <div className="flex items-center justify-between p-4">
          <button
            onClick={closeSidebar}
            className="p-1 rounded-md hover:bg-gray-100 lg:hidden"
          >
            <X size={24} />
          </button>
        </div>

        <nav className="flex-1 p-4 overflow-y-auto">
          <ul className="space-y-4">
            {filteredSidebarItems.map((item, index) => (
              <li key={index}>
                <MenuItem item={item} />
              </li>
            ))}
          </ul>
        </nav>

        <div className="border-t p-4 space-y-2">
          <button
            onClick={logout}
            className="w-full flex items-center space-x-2 p-2 rounded-md hover:bg-red-50 text-red-600 hover:text-red-700"
          >
            <LogOut size={20} />
            <span>Logout</span>
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
