import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import moment from 'moment';
import { TransactionsFilters } from 'utils/types';

const downloadTransactionsReports = async (data: TransactionsFilters) => {
  const post = {
    fromDate: moment(data.fromDate).format('YYYY-MM-DDTHH:mm'),
    toDate: moment(data.toDate).format('YYYY-MM-DDTHH:mm'),
    chanel: data.chanel,
  };
  const response = await axiosInstance.post('reports/payments', post);
  return response.data;
};

const useDownloadTransactionsReports = (): UseMutationResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Error,
  TransactionsFilters
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: downloadTransactionsReports,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['paymentsreports'] });
    },
    onError: (error: Error) => {
      console.error('Error filtering fines:', error.message);
    },
  });
};

export default useDownloadTransactionsReports;
