import React from 'react';
import DialogLayout from 'layouts/DialogLayout';
import { FileUpload } from './FineUpload';

interface FileUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FileUploadDialog: React.FC<FileUploadDialogProps> = ({
  isOpen,
  onClose,
}) => (
  <DialogLayout width="100%" isOpen={isOpen} onClose={onClose}>
    <div className="py-12 px-12">
      <FileUpload />
    </div>
  </DialogLayout>
);
