import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import TableSkeleton from 'components/common/skeletons/TableSkeleton';
import FinesTable from 'components/fines/FinesTable';
import useProcessCorrectedPlate from 'hooks/fines/useProcessCorrectedPlate';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { useFines } from 'context/FinesContext';
import { Fine } from 'utils/types';

const Fines = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [localFines, setLocalFines] = useState<Fine[]>([]);
  const {
    currentPage,
    pageSize,
    fines,
    totalPages,
    isLoading: finesLoading,
    goToPage,
    handleFilter,
  } = useFines();

  const { mutateAsync: processCorrectedPlateMutation, isPending } =
    useProcessCorrectedPlate();

  useEffect(() => {
    if (fines) {
      setLocalFines(fines);
    }
  }, [fines]);

  const handleRowUpdate = async (updatedRow: Fine) => {
    setLocalFines((prevFines) =>
      prevFines.map((fine) => (fine.id === updatedRow.id ? updatedRow : fine)),
    );
    const post = {
      ticketNumber: updatedRow.id.toString(),
      vehicleRegistrationNumber: updatedRow.vehicleRegistrationNumber,
      offences: [],
      userId: keycloak?.tokenParsed.sub,
    };
    await processCorrectedPlateMutation(post)
      .then(() =>
        toast.success('The registration was updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        }),
      )
      .catch(() =>
        toast.error('There was an error updating the registration number', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        }),
      );
  };

  const handleRowClick = (fine: Fine) => {
    const currentIndex = localFines.findIndex((f) => f.id === fine.id);
    navigate(`/fines/single/${fine.id}`, {
      state: {
        currentIndex,
        totalPages,
        currentPage,
      },
    });
  };

  if (finesLoading) return <TableSkeleton />;

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <FinesTable
        data={localFines}
        onRowClick={handleRowClick}
        currentPage={currentPage}
        pageSize={pageSize}
        goToPage={goToPage}
        totalPages={totalPages}
        onRowUpdate={handleRowUpdate}
        isLoading={isPending}
        onFilter={handleFilter}
      />
    </div>
  );
};

export default Fines;
