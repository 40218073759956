import React, { useCallback, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogIn } from 'lucide-react';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (keycloak?.authenticated) {
      if (location.pathname === '/auth/login') {
        navigate('/');
      }
    }
  }, [keycloak?.authenticated, initialized, navigate, location.pathname]);

  useEffect(() => {
    localStorage.setItem('authType', 'keycloak');
    return () => {
      localStorage.removeItem('authType');
    };
  }, []);

  const login = useCallback(async () => {
    if (keycloak) {
      try {
        await keycloak.login({
          redirectUri: window.location.origin + '/',
        });
      } catch (error) {
        console.error('Login failed:', error);
      }
    } else {
      console.error('Keycloak instance is not available');
    }
  }, [keycloak]);

  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (keycloak?.authenticated && location.pathname !== '/auth/login') {
    return null;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-100 to-gray-200 p-4">
      <div className="w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="p-8 text-center">
          <div className="mb-8">
            <div className="w-24 h-24 mx-auto mb-6 bg-indigo-100 rounded-full flex items-center justify-center">
              <LogIn className="w-12 h-12 text-black" />
            </div>
            <h1 className="text-3xl font-bold text-gray-800 mb-4">
              Access Your Dashboard
            </h1>
            <p className="text-gray-600 text-sm">
              Securely authenticate to continue to your personalized workspace
            </p>
          </div>

          <button
            onClick={login}
            className="w-full py-3 px-4 
              bg-gradient-to-r from-indigo-600 to-purple-600 text-white 
              font-semibold rounded-lg 
              hover:from-indigo-700 hover:to-purple-700 
              focus:outline-none focus:ring-2 focus:ring-offset-2 
              focus:ring-indigo-500 
              transition-all duration-300 
              ease-in-out transform hover:scale-105 active:scale-95 
              flex items-center justify-center gap-3"
          >
            <LogIn className="w-5 h-5" />
            Click to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
