/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';

interface DataItem {
  name: string;
  value: number;
  color: string;
}

interface PieChartProps {
  pieData: DataItem[];
  pieRadius: number;
}

const RADIAN = Math.PI / 180;

const PieChartWithTooltips: React.FC<PieChartProps> = ({
  pieData,
  pieRadius,
}) => {
  const totalValue = pieData.reduce((sum, entry) => sum + entry.value, 0);

  const renderCustomizedLabel = (props: any) => {
    const { cx, cy, midAngle, outerRadius, value } = props;
    const radius = outerRadius + 30;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const percentage = ((value / totalValue) * 100).toFixed(1);

    const curveRadius = outerRadius + 15;
    const controlX = cx + curveRadius * Math.cos(-midAngle * RADIAN);
    const controlY = cy + curveRadius * Math.sin(-midAngle * RADIAN);

    const anchorRadius = outerRadius + 5;
    const anchorX = cx + anchorRadius * Math.cos(-midAngle * RADIAN);
    const anchorY = cy + anchorRadius * Math.sin(-midAngle * RADIAN);

    const textAnchor = x > cx ? 'start' : 'end';

    return (
      <g>
        <path
          d={`M ${anchorX},${anchorY} Q ${controlX},${controlY} ${x},${y}`}
          fill="none"
          stroke="#666"
          strokeWidth={1}
        />
        <circle cx={x} cy={y} r={2} fill="#666" />

        <text
          x={x}
          y={y}
          textAnchor={textAnchor}
          fill="#666"
          dominantBaseline="middle"
          className="text-sm"
          dx={x > cx ? 5 : -5}
        >
          {`${percentage}%`}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const { name, value, color } = payload[0].payload;
      return (
        <div className="bg-white p-2 border border-gray-300 rounded shadow-sm">
          <p style={{ color }}>{`${name}: KES ${value.toLocaleString()}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mt-4">
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            innerRadius={pieRadius}
            outerRadius={100}
            startAngle={90}
            endAngle={-270}
            dataKey="value"
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>

      <div className="mt-6 flex flex-wrap justify-center gap-4">
        {pieData.map((entry, index) => (
          <div key={`legend-${index}`} className="flex items-center gap-2">
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm text-gray-700">{entry.name}</span>
            <span className="text-sm text-gray-500">
              KES {entry.value.toLocaleString()}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChartWithTooltips;
