import React, { useState } from 'react';
import { Column } from '.';

interface ThumbnailCellProps {
  src: string;
  thumbnailConfig?: Column['thumbnail'];
}

export const ThumbnailCell: React.FC<ThumbnailCellProps> = ({
  src,
  thumbnailConfig,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img
        src={src}
        alt="Thumbnail"
        width={thumbnailConfig?.width || 50}
        height={thumbnailConfig?.height || 50}
        className="object-cover rounded"
      />
      {isHovering && (
        <div
          className="fixed z-50 pointer-events-none"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '80vw',
            maxHeight: '80vh',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          }}
        >
          <img
            src={src}
            alt="Full Image"
            className="max-w-full max-h-full object-contain rounded-lg shadow-xl"
          />
        </div>
      )}
    </div>
  );
};
