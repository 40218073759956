import { useState, useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FileUploadProps } from 'utils/types';
import { uploadService } from 'utils/api/FileUpload';

export const useFileUpload = ({
  maxSize = 100 * 1024 * 1024,
  multiple = false,
  onSuccess,
  onError,
  uploadEndpoint = 'fines/upload',
}: FileUploadProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (filesToUpload: File[]) =>
      uploadService.uploadFiles(filesToUpload, uploadEndpoint, (progress) =>
        setUploadProgress(progress),
      ),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['fines', 'fine'] });
      onSuccess?.(data);
      setFiles([]);
      setUploadProgress(0);
    },
    onError: (error) => {
      onError?.(error as Error);
    },
  });

  const handleFileSelect = useCallback(
    (newFiles: File[]) => {
      const validFiles = newFiles.filter((file) => file.size <= maxSize);
      if (!multiple && validFiles.length > 1) {
        validFiles.splice(1);
      }
      setFiles(validFiles);
    },
    [maxSize, multiple],
  );

  const handleFileRemove = useCallback((index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleUpload = useCallback(() => {
    if (files.length > 0) {
      setUploadProgress(0);
      mutation.mutate(files);
    }
  }, [files, mutation]);

  return {
    files,
    uploadProgress,
    isDragActive: dragActive,
    isUploading: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    errorMessage:
      mutation.error instanceof Error ? mutation.error.message : undefined,
    onFileSelect: handleFileSelect,
    onFileRemove: handleFileRemove,
    onUpload: handleUpload,
    onDragStateChange: setDragActive,
  };
};
