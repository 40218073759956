import React, { useState, useTransition } from 'react';

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabbedInterfaceProps {
  tabs: Tab[];
}

const TabbedInterface = ({ tabs }: TabbedInterfaceProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isPending, startTransition] = useTransition();

  const handleTabChange = (index: number) => {
    startTransition(() => {
      setActiveTab(index);
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="flex space-x-4 border-b border-gray-200 px-6 py-4">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabChange(index)}
            className={`px-4 py-2 ${
              activeTab === index
                ? 'border-b-2 border-ntsaGreen text-ntsaGreen'
                : 'text-gray-500'
            }`}
            disabled={isPending} // Disable buttons during transition
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="p-6">
        {isPending ? (
          <div className="flex justify-center items-center h-20">
            <span className="text-gray-500">Loading...</span>
          </div>
        ) : (
          tabs[activeTab].content
        )}
      </div>
    </div>
  );
};

export default TabbedInterface;
