/* eslint-disable @typescript-eslint/no-unused-vars */
import TableSkeleton from 'components/common/skeletons/TableSkeleton';
import TransactionsTable from 'components/transactions/TransactionTable';
import useGetTransactions from 'hooks/payments/useGetTransactions';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Transaction } from 'utils/types';

const Reports = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 10;
  const [localTransactions, setLocalTransactions] = useState<Transaction[]>([]);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page') || '1', 10),
  );

  const initialFilters = {
    transactionCode: searchParams.get('transactionCode') || '',
    transactionDateTime: searchParams.get('transactionDateTime') || '',
    chanel: searchParams.get('chanel') || '',
    amount: searchParams.get('amount') || '',
    id: searchParams.get('id') || '',
    dateCaptured: searchParams.get('dateCaptured') || '',
  };

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1', 10);
    setCurrentPage(page);
  }, [searchParams]);

  const cleanFilters = (filtersParams: Record<string, string>) => {
    return Object.fromEntries(
      Object.entries(filtersParams).filter(([_, value]) => value !== ''),
    );
  };

  const { data: transactions, isLoading: transactionsLoading } =
    useGetTransactions({
      options: {
        page: currentPage.toString(),
        pageSize: pageSize.toString(),
        filters: cleanFilters(filters),
      },
    });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageNumber: (currentPage - 1).toString(),
      pageSize: pageSize.toString(),
    }));
  }, [currentPage]);

  useEffect(() => {
    if (transactions?.paymentsList !== undefined) {
      setLocalTransactions(transactions.paymentsList);
    }
  }, [transactions]);

  const goToPage = (pageNumber: number) => {
    if (
      transactions !== undefined &&
      pageNumber > 0 &&
      pageNumber <= transactions.totalPages
    ) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', pageNumber.toString());
      setSearchParams(newSearchParams);
    }
  };

  const handleFilter = (columnKey: string, value: string) => {
    const newFilters = { ...filters, [columnKey]: value };
    setFilters(newFilters);

    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(columnKey, value);
    } else {
      newSearchParams.delete(columnKey);
    }
    setSearchParams(newSearchParams);
  };

  const handleRowClick = (transaction: Transaction) => {
    const currentIndex = localTransactions.findIndex(
      (f) => f.fine.id === transaction.fine.id,
    );
    navigate(`/fines/single/${transaction.fine.id}`, {
      state: {
        currentIndex,
        filters,
        totalPages: transactions?.totalPages || 0,
        currentPage,
      },
    });
  };

  if (transactionsLoading) return <TableSkeleton />;

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <TransactionsTable
        data={localTransactions}
        onRowClick={handleRowClick}
        currentPage={currentPage}
        pageSize={pageSize}
        goToPage={goToPage}
        totalPages={transactions?.totalPages || 0}
        onFilter={handleFilter}
      />
    </div>
  );
};

export default Reports;
