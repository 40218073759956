import * as React from 'react';

type MetricsCardProps = {
  title: string;
  value: string;
  subtitle: string;
};

const MetricsCard = ({ title, value, subtitle }: MetricsCardProps) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
      <div className="space-y-2">
        <p className="text-sm text-gray-600">{title}</p>

        <div className="flex items-center space-x-2">
          <span className="text-2xl font-semibold">{value}</span>
        </div>

        <p className="text-sm text-gray-500">{subtitle}</p>
      </div>
    </div>
  );
};

export default MetricsCard;
