/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Eye, Edit, Check, X } from 'lucide-react';
import { ThumbnailCell } from './ThumbnailCell';
import LoadingDots from '../loadingdots/LoadingDots';
import { Column } from '.';
import { getNestedValue } from 'utils/Functions';

interface TableRowProps<T> {
  row: T;
  columns: Column[];
  rowIndex: number;
  editingRow: number | null;
  editedRowData: Partial<T> | null;
  isLoading?: boolean;
  onRowClick?: (row: T) => void;
  handleEditStart: (rowIndex: number) => void;
  handleEditCancel: () => void;
  handleEditSave: () => void;
  handleFieldChange: (key: string, value: any) => void;
  isProcessor: boolean;
}

const DefaultEditInput = ({
  value,
  onChange,
  onBlur,
}: {
  value: any;
  onChange: (value: any) => void;
  onBlur?: () => void;
}) => (
  <input
    type="text"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    onBlur={onBlur}
    className="w-full px-2 py-1 border rounded"
  />
);

export const TableRow = <T extends object>({
  row,
  columns,
  rowIndex,
  editingRow,
  editedRowData,
  isLoading,
  onRowClick,
  handleEditStart,
  handleEditCancel,
  handleEditSave,
  handleFieldChange,
  isProcessor,
}: TableRowProps<T>) => {
  return (
    <tr
      key={rowIndex}
      className={`${onRowClick ? 'cursor-pointer hover:bg-gray-50' : ''} transition-colors}`}
    >
      {columns.map((column, colIndex) => {
        const cellValue = getNestedValue(row, column.accessorKey);

        if (column.thumbnail) {
          return (
            <td
              key={colIndex}
              className="px-4 sm:px-6 py-4 text-sm text-gray-600 whitespace-nowrap"
            >
              <ThumbnailCell
                src={cellValue}
                thumbnailConfig={column.thumbnail}
              />
            </td>
          );
        }

        // Check if row is being edited
        if (editingRow === rowIndex && column.editable) {
          const EditComponent = column.editComponent || DefaultEditInput;
          return (
            <td
              key={colIndex}
              className="px-4 sm:px-6 py-4 text-sm text-gray-600 whitespace-nowrap"
            >
              <EditComponent
                value={
                  editedRowData?.[
                    column.accessorKey as keyof typeof editedRowData
                  ]
                }
                onChange={(value) =>
                  handleFieldChange(
                    column.accessorKey as keyof typeof editedRowData,
                    value,
                  )
                }
              />
            </td>
          );
        }

        // Normal cell rendering
        return (
          <td
            key={colIndex}
            className="px-4 sm:px-6 py-4 text-sm text-gray-600 whitespace-nowrap"
          >
            {column.cell ? column.cell(cellValue) : cellValue}
          </td>
        );
      })}

      <td className="px-4 sm:px-6 py-4 text-sm text-gray-600 whitespace-nowrap">
        {isLoading ? (
          <LoadingDots className="justify-center" />
        ) : editingRow === rowIndex ? (
          <div className="flex space-x-2">
            <button
              onClick={handleEditSave}
              className="text-green-600 hover:bg-green-50 p-1 rounded"
            >
              <Check size={20} />
            </button>
            <button
              onClick={handleEditCancel}
              className="text-red-600 hover:bg-red-50 p-1 rounded"
            >
              <X size={20} />
            </button>
          </div>
        ) : (
          <div className="flex space-x-4">
            <button
              onClick={() => onRowClick?.(row)}
              className="text-blue-600 hover:bg-blue-50 p-1 rounded"
            >
              <Eye size={20} />
            </button>
            {isProcessor && (
              <button
                onClick={() => handleEditStart(rowIndex)}
                className="text-blue-600 hover:bg-blue-50 p-1 rounded"
              >
                <Edit size={20} />
              </button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};
