import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  fallbackPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  fallbackPath = '/auth/login',
}) => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();
  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }
  if (!keycloak?.authenticated) {
    return <Navigate to={fallbackPath} state={{ from: location.pathname }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
