import { BarChart3 } from 'lucide-react';
import * as React from 'react';

const NoDataSVG = () => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-4 text-gray-400">
    <div className="bg-gray-50 p-8 rounded-full">
      <BarChart3 size={48} className="text-gray-400" />
    </div>
    <div className="text-center">
      <p className="text-xl font-medium text-gray-500 mb-2">
        No Data Available
      </p>
      <p className="text-sm text-gray-400">There are no records to display</p>
    </div>
  </div>
);

export default NoDataSVG;
