import React from 'react';
import Table from 'components/common/table';
import { Transaction } from 'utils/types';
import { getTableColumns } from './TableColumns';
import { ReportDialog } from './ReportDialog';
import { TableHeader } from './TableHeader';
import { useTransactionsTable } from 'hooks/common/useTransactionsTable';

interface FinesTableProps {
  data: Transaction[];
  onRowClick?: (transaction: Transaction) => void;
  pageSize?: number;
  currentPage?: number;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
  onFilter?: (columnKey: string, value: string) => void;
}

const TransactionsTable: React.FC<FinesTableProps> = ({
  data: initialData,
  onRowClick,
  pageSize,
  currentPage,
  totalPages,
  goToPage,
  onFilter,
}) => {
  const columns = getTableColumns();
  const {
    filters,
    setFilters,

    isReportOpen,
    setIsReportOpen,

    errors,
    isPendingCSV,
    isPendingPDF,
    downloadCSV,
    downloadPDF,
  } = useTransactionsTable();
  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <TableHeader onReportOpen={() => setIsReportOpen(true)} />
        {isReportOpen && (
          <ReportDialog
            isOpen={isReportOpen}
            onClose={() => setIsReportOpen(false)}
            filters={filters}
            setFilters={setFilters}
            onDownloadCSV={downloadCSV}
            onDownloadPDF={downloadPDF}
            isPendingCSV={isPendingCSV}
            isPendingPDF={isPendingPDF}
            errors={errors}
          />
        )}
      </div>

      <div className="container mx-auto px-4">
        <Table
          data={initialData}
          columns={columns}
          pageSize={pageSize}
          onRowClick={onRowClick}
          currentPage={currentPage}
          goToPage={goToPage}
          totalPages={totalPages}
          onFilter={onFilter}
          pagination={true}
        />
      </div>
    </div>
  );
};

export default TransactionsTable;
