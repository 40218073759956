import { useKeycloak } from '@react-keycloak/web';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface AuthContextType {
  auth: string[];
  setAuth: (value: string[]) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { keycloak, initialized } = useKeycloak();
  const [auth, setAuth] = useState<string[]>(['admin']);
  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      const roles = keycloak?.tokenParsed?.resource_access?.enforce?.roles || [
        'admin',
      ];
      setAuth(roles);
    }
  }, [initialized, keycloak]);
  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
