import React from 'react';

interface OtpVerificationFormProps {
  otp: string[];
  setOtp: (otp: string[]) => void;
  timeLeft: number;
  canResend: boolean;
  onResendOtp: () => void;
  onSubmit: (otp: string[]) => void;
  isPending: boolean;
  onBack: () => void;
}

const OtpVerificationForm: React.FC<OtpVerificationFormProps> = ({
  otp,
  setOtp,
  timeLeft,
  canResend,
  onResendOtp,
  onSubmit,
  isPending,
  onBack,
}) => {
  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleOtpChange = (element: HTMLInputElement, index: number) => {
    // Only allow numeric input
    const value = element.value.replace(/[^0-9]/g, '');

    if (value === '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); // Only take the last digit if multiple are pasted
    setOtp(newOtp);

    // Move to next input if a digit was entered
    if (value && index < 5) {
      const nextInput = element.nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    // Allow only numbers, backspace, delete, tab, arrow keys
    const allowedKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
    ];
    const isNumber = /^[0-9]$/.test(e.key);

    if (!isNumber && !allowedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    }

    // Handle backspace logic
    if (e.key === 'Backspace') {
      if (!otp[index] && index > 0) {
        const prevInput = (e.target as HTMLInputElement)
          .previousElementSibling as HTMLInputElement;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(otp);
  };

  const handlePaste = (e: React.ClipboardEvent, index: number) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const numbers = pastedData.replace(/[^0-9]/g, '').split('');

    if (numbers.length === 0) return;

    // Fill in as many digits as we can
    const newOtp = [...otp];
    for (let i = 0; i < 6 - index; i++) {
      if (i < numbers.length) {
        newOtp[index + i] = numbers[i];
      }
    }
    setOtp(newOtp);

    // Focus on the next empty input or the last input
    const inputs = Array.from(document.querySelectorAll('input[type="text"]'));
    const nextEmptyIndex = newOtp.findIndex((val, idx) => idx >= index && !val);
    if (nextEmptyIndex !== -1 && nextEmptyIndex < 6) {
      (inputs[nextEmptyIndex] as HTMLInputElement).focus();
    } else {
      (inputs[5] as HTMLInputElement).focus();
    }
  };

  return (
    <>
      <p className="text-base text-center text-gray-700 leading-relaxed">
        Enter the 6-digit OTP sent to your registered phone number.
      </p>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex justify-center space-x-4">
          {[...Array(6)].map((_, index) => (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={1}
              value={otp[index]}
              onChange={(e) => handleOtpChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={(e) => handlePaste(e, index)}
              className="w-10 h-10 text-center text-2xl font-semibold border-2 border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
            />
          ))}
        </div>

        <div className="text-center space-y-4">
          {!canResend ? (
            <p className="text-gray-600">
              Resend OTP in {formatTime(timeLeft)}
            </p>
          ) : (
            <button
              type="button"
              onClick={onResendOtp}
              className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
            >
              Resend OTP
            </button>
          )}
        </div>

        <button
          type="submit"
          disabled={isPending || otp.some((digit) => !digit)}
          className="w-full py-2 px-4 bg-ntsaGreen text-white rounded-md hover:bg-blue-700 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isPending ? 'Verifying...' : 'Verify OTP'}
        </button>

        <button
          type="button"
          onClick={onBack}
          className="w-full py-2 px-4 text-gray-600 hover:text-gray-800 transition-colors duration-300"
        >
          Back to Vehicle Registration
        </button>
      </form>
    </>
  );
};

export default OtpVerificationForm;
