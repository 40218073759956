import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import PieChartWithTooltips from 'components/dashboard/charts/PieChart';
import NoDataSVG from 'pages/dashboard/NoDataSVG';

interface BarData {
  name: string;
  Mpesa: number;
  Card: number;
  Noti: number;
  Teller: number;
}

interface PieData {
  name: string;
  value: number;
  color: string;
}

const Charts = ({
  barData,
  pieData,
  paymentMethodsPieData,
  cameraRevenuePieData,
}: {
  barData: BarData[];
  pieData: PieData[];
  paymentMethodsPieData: PieData[];
  cameraRevenuePieData: PieData[];
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between pb-2">
          <h3 className="text-base font-medium text-gray-900">
            Revenue by Camera
          </h3>
        </div>
        <div className="mt-4">
          {cameraRevenuePieData[0].value > 0 ? (
            <PieChartWithTooltips
              pieRadius={60}
              pieData={cameraRevenuePieData}
            />
          ) : (
            <NoDataSVG />
          )}
        </div>
      </div>
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between pb-2">
          <div>
            <h3 className="text-base font-medium text-gray-900">
              Revenue Chart
            </h3>
          </div>
        </div>
        <div className="mt-4">
          {barData.length > 0 ? (
            <ResponsiveContainer width="100%" height={450}>
              <BarChart
                data={barData}
                margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{ dy: 10 }}
                />
                <YAxis
                  label={{
                    value: 'Amount (Kshs)',
                    angle: -90,
                    position: 'insideLeft',
                    offset: -15,
                  }}
                  axisLine={false}
                  tickLine={false}
                  tick={{ dx: -10 }}
                />
                <Tooltip />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  wrapperStyle={{ paddingTop: 20 }}
                />
                <Bar
                  dataKey="Mpesa"
                  fill="#4472C4"
                  radius={[4, 4, 0, 0]}
                  maxBarSize={40}
                />
                <Bar
                  dataKey="Card"
                  fill="#ED7D31"
                  radius={[4, 4, 0, 0]}
                  maxBarSize={40}
                />
                <Bar
                  dataKey="Noti"
                  fill="#A5A5A5"
                  radius={[4, 4, 0, 0]}
                  maxBarSize={40}
                />
                <Bar
                  dataKey="Teller"
                  fill="#FFC000"
                  radius={[4, 4, 0, 0]}
                  maxBarSize={40}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <NoDataSVG />
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between pb-2">
          <h3 className="text-base font-medium text-gray-900">
            Payment Status
          </h3>
        </div>
        <div className="mt-4">
          <PieChartWithTooltips pieRadius={0} pieData={pieData} />
        </div>
      </div>
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between pb-2">
          <h3 className="text-base font-medium text-gray-900">
            Payment Methods
          </h3>
        </div>
        <div className="mt-4">
          {paymentMethodsPieData[0].value > 0 ? (
            <PieChartWithTooltips
              pieRadius={60}
              pieData={paymentMethodsPieData}
            />
          ) : (
            <NoDataSVG />
          )}
        </div>
      </div>
    </div>
  );
};

export default Charts;
