import Card from 'components/dashboard/Card';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import MapComponent from './Map';
import { AlertTriangle, Ban, Camera, Clock } from 'lucide-react';
import DashboardSkeleton from 'components/common/skeletons/DashboardSkeleton';
import * as Sentry from '@sentry/react';
import FiltersComponent from './FilterComponent';
import useFilterFines from 'hooks/fines/useFilterFines';
import { Fine } from 'utils/types';
import moment from 'moment';
import NoDataSVG from './NoDataSVG';
import { getFirstTwoLetters } from 'utils/Functions';
import { Bounce, toast } from 'react-toastify';

// ... (keep existing interfaces)
interface AggregatedDataItem {
  date: string;
  count: number;
  totalAmount: number;
}
interface AggregatedHourlyItem {
  count: number;
  hour: number;
  totalAmount: number;
}

interface DailyAggregation {
  [key: string]: AggregatedDataItem;
}
interface HourlyAggregation {
  [key: string]: AggregatedHourlyItem;
}
export interface Response {
  finesListEmpty: string;
  finesList: Fine[];
}
const Dashboard = () => {
  const { mutateAsync, isPending, isError: finesError } = useFilterFines();

  if (finesError) {
    Sentry.captureException(finesError);
  }

  const [selectedDateRange, setSelectedDateRange] = React.useState({
    from: new Date(new Date().setDate(new Date().getDate() - 1)),
    to: new Date(),
  });

  const [filteredFines, setFilteredFines] = useState<Fine[]>([]);

  const getFilteredFines = async () => {
    const post = {
      fromDate: moment(selectedDateRange.from).format('YYYY-MM-DDTHH:mm'),
      toDate: moment(selectedDateRange.to).format('YYYY-MM-DDTHH:mm'),
      location: '',
      vehicleRegistrationNumber: '',
      paymentStatus: '',
      sms_status: '',
    };
    try {
      const data: Response = await await mutateAsync(post).catch(() =>
        toast.error('Error fetching fines data', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        }),
      );
      if (data.finesListEmpty !== 'true') {
        setFilteredFines(data.finesList);
      } else {
        setFilteredFines([]);
      }
    } catch (error) {
      console.error('Error fetching fines data:', error);
    }
  };
  useEffect(() => {
    getFilteredFines();
  }, []);

  const handleDateChange = (field: 'from' | 'to', date: Date) => {
    setSelectedDateRange((prev) => ({
      ...prev,
      [field]: date,
    }));
  };
  const getCameraSpecificStats = (fines: Fine[], cameraType: string) => {
    const cameraFines = fines.filter(
      (fine) => getFirstTwoLetters(fine.violationId, 2) === cameraType,
    );
    return {
      total: cameraFines.length,
      sent: cameraFines.filter((item) => item.smsStatus === 'S').length,
      failed: cameraFines.filter((item) => item.smsStatus === 'F').length,
      pending: cameraFines.filter((item) => item.smsStatus === 'P').length,
    };
  };

  const vitronicsStats = useMemo(
    () => getCameraSpecificStats(filteredFines, 'PS'),
    [filteredFines],
  );

  const arhStats = useMemo(
    () => getCameraSpecificStats(filteredFines, 'GD'),
    [filteredFines],
  );

  const cardItems = [
    {
      title: 'Total SMS',
      figure: filteredFines.length,
      icon: Ban,
    },
    {
      title: 'Vitronics SMS Sent',
      figure: vitronicsStats.sent,
      icon: Camera,
      color: 'text-blue-600',
    },
    {
      title: 'Vitronics SMS Pending',
      figure: vitronicsStats.pending,
      icon: Clock,
      color: 'text-yellow-600',
    },
    {
      title: 'Vitronics SMS Failed',
      figure: vitronicsStats.failed,
      icon: AlertTriangle,
      color: 'text-red-600',
    },
    {
      title: 'ARH SMS Sent',
      figure: arhStats.sent,
      icon: Camera,
      color: 'text-green-600',
    },
    {
      title: 'ARH SMS Pending',
      figure: arhStats.pending,
      icon: Clock,
      color: 'text-yellow-600',
    },
    {
      title: 'ARH SMS Failed',
      figure: arhStats.failed,
      icon: AlertTriangle,
      color: 'text-red-600',
    },
  ];
  const aggregatedData = useMemo(() => {
    const groupedByDay = filteredFines.reduce<DailyAggregation>((acc, curr) => {
      // Get date without time
      const date = new Date(curr.dateCaptured).toISOString().split('T')[0];

      if (!acc[date]) {
        acc[date] = {
          date,
          count: 0,
          totalAmount: 0,
        };
      }

      acc[date].count += 1;
      acc[date].totalAmount += curr.amount;

      return acc;
    }, {});

    // Convert to array and sort by date
    return Object.values(groupedByDay ?? {}).sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  }, [filteredFines]);
  const aggregatedDataHourly = useMemo(() => {
    const groupedByHour = filteredFines.reduce<HourlyAggregation>(
      (acc, curr) => {
        // Extract the hour from the dateCaptured
        const date = new Date(curr.dateCaptured);
        const hour = date.getHours(); // Only group by hour, ignore the day

        // Use the hour as the key (e.g., '10:00' for 10 AM)
        const hourKey = `${String(hour).padStart(2, '0')}:00`;

        if (!acc[hourKey]) {
          acc[hourKey] = {
            hour,
            count: 0,
            totalAmount: 0,
          };
        }

        acc[hourKey].count += 1;
        acc[hourKey].totalAmount += curr.amount;

        return acc;
      },
      {},
    );

    // Convert the aggregated data to an array and sort by hour
    return Object.values(groupedByHour ?? {}).sort((a, b) => a.hour - b.hour);
  }, [filteredFines]);
  const smsData = useMemo(() => {
    return [
      {
        name: 'Vitronics',
        failed: vitronicsStats.failed,
        sent: vitronicsStats.sent,
        pending: vitronicsStats.pending,
      },
      {
        name: 'ARH',
        failed: arhStats.failed,
        sent: arhStats.sent,
        pending: arhStats.pending,
      },
    ];
  }, [vitronicsStats, arhStats]);

  const aggregatedDataByCoordinates = useMemo(() => {
    const groupedByCoordinates = filteredFines.reduce<{
      [key: string]: { lat: number; lng: number; count: number };
    }>((acc, curr) => {
      if (!curr.gps || !curr.gps.includes(',')) return acc;

      const gps = curr.gps.split(',');
      const lat = parseFloat(gps[0]);
      const lng = parseFloat(gps[1]);

      if (isNaN(lat) || isNaN(lng)) return acc;

      const coordinateKey = `${lat},${lng}`;

      if (!acc[coordinateKey]) {
        acc[coordinateKey] = {
          lat,
          lng,
          count: 0,
        };
      }

      acc[coordinateKey].count += 1;

      return acc;
    }, {});

    return Object.values(groupedByCoordinates ?? []);
  }, [filteredFines]);
  if (isPending) return <DashboardSkeleton />;

  return (
    <div className="lg:p-6 p-2">
      <FiltersComponent
        selectedDateRange={selectedDateRange}
        handleDateChange={handleDateChange}
        onSubmit={getFilteredFines}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
        {cardItems.map((item) => (
          <div key={item.title} className="h-full">
            <Card>
              <div className="flex space-x-12 items-center ml-12 ">
                <item.icon size={30} className={item.color} />
                <div>
                  <h3 className="text-black text-lg font-medium">
                    {item.title}
                  </h3>
                  <p
                    className={`text-2xl font-bold mt-3 ${item.color || 'text-chartBlue'}`}
                  >
                    {item.figure}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
      {/* Update the SMS chart section */}
      <div className="grid grid-cols-1 mt-8 sm:grids-cols-2 lg:grid-cols-2 gap-6">
        <Card>
          <h3 className=" text-gray-600 mb-5 text-lg font-medium">
            Fines collection overview
          </h3>
          {aggregatedData.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={aggregatedData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  angle={-45}
                  textAnchor="end"
                  height={70}
                  dataKey="date"
                />
                <YAxis
                  label={{
                    value: 'Number of Fines',
                    angle: -90,
                    position: 'insideLeft',
                    offset: 10,
                  }}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#3282b8"
                  strokeWidth={2}
                  dot={{ fill: '#3282b8', strokeWidth: 2 }}
                  name="Number of Fines"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <NoDataSVG />
          )}
        </Card>
        <Card>
          <h3 className=" text-gray-600 mb-5 text-lg font-medium">
            Fines occurences by time of the day
          </h3>
          {aggregatedDataHourly.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={aggregatedDataHourly}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  angle={-45}
                  textAnchor="end"
                  height={70}
                  dataKey="hour"
                />
                <YAxis
                  label={{
                    value: 'Number of Fines',
                    angle: -90,
                    position: 'insideLeft',
                    offset: 10,
                  }}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#3282b8"
                  strokeWidth={2}
                  dot={{ fill: '#3282b8', strokeWidth: 2 }}
                  name="Number of Fines"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <NoDataSVG />
          )}
        </Card>
        <Card>
          <h3 className="text-gray-600 mb-5 text-lg font-medium">
            SMS delivery overview by camera type
          </h3>
          {smsData.every(
            (data) =>
              data.failed === 0 && data.pending === 0 && data.sent === 0,
          ) ? (
            <NoDataSVG />
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={smsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="sent"
                  fill="#10B981"
                  barSize={30}
                  name="SMS Sent"
                />
                <Bar
                  dataKey="pending"
                  fill="#F59E0B"
                  barSize={30}
                  name="SMS Pending"
                />
                <Bar
                  dataKey="failed"
                  fill="#F87171"
                  barSize={30}
                  name="SMS Failed"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Card>
        <Card>
          <h3 className=" text-gray-600 mb-5 text-lg font-medium">
            Violation location overview
          </h3>
          {aggregatedDataByCoordinates.length > 0 ? (
            <MapComponent coordinates={aggregatedDataByCoordinates} />
          ) : (
            <NoDataSVG />
          )}
        </Card>
      </div>
    </div>
  );
};

export default Sentry.withProfiler(Dashboard);
