/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import useFilterFines from 'hooks/fines/useFilterFines';
import { Fine, FinesFilters, Response } from 'utils/types';

export const useFinesTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [errors, setErrors] = useState({ start: false, end: false });

  const { mutateAsync: filterFinesCSV, isPending: isPendingCSV } =
    useFilterFines();
  const { mutateAsync: filterFinesPDF, isPending: isPendingPDF } =
    useFilterFines();

  const [filters, setFilters] = useState<FinesFilters>({
    location: searchParams.get('location') || '',
    vehicleInfo: searchParams.get('vehicleInfo') || '',
    dateRange: {
      start: searchParams.get('startDate') || '',
      end: searchParams.get('endDate') || '',
    },
    amountRange: {
      min: searchParams.get('minAmount') || '',
      max: searchParams.get('maxAmount') || '',
    },
    paymentStatus: searchParams.get('paymentStatus') || '',
    sms_Status: searchParams.get('sms_Status') || '',
  });

  const validateDates = () => {
    const newErrors = {
      start: !filters.dateRange.start,
      end: !filters.dateRange.end,
    };
    setErrors(newErrors);
    return !newErrors.start && !newErrors.end;
  };

  const exportPDF = (data: any[]) => {
    const doc = new jsPDF({ orientation: 'landscape' }); // Use landscape mode for more space
    doc.text('Fines Report', 20, 10);

    autoTable(doc, {
      head: [
        [
          'Ticket No.',
          'Vehicle',
          'SMS',
          'Violation ID',
          'Loc.',
          'Date',
          'Reg. No',
          'Time',
          'Status',
          'Limit',
          'Speed',
          'Fine',
        ],
      ],
      body: data.map((item) => [
        item.fineId,
        item.vehicleType,

        item.smsStatus,
        item.violationId,
        item.fineLocation,
        moment(item.fineDate).format('YYYY-MM-DD'),
        item.fineVehicleReg,
        moment(item.fineDate).format('hh:mm A'),
        item.fineStatus,
        item.fineSpeedLimit,
        item.fineVehicleSpeed,
        item.totalAmount,
      ]),
      styles: { fontSize: 8 }, // Reduce font size
      columnStyles: {
        2: { cellWidth: 10 }, // Reduce Image column width
        6: { cellWidth: 20 }, // Reduce Date column width
        7: { cellWidth: 25 }, // Vehicle Registration
        8: { cellWidth: 20 }, // Time column width
      },
    });

    doc.save('fines-report.pdf');
  };

  const downloadPDF = async () => {
    if (validateDates()) {
      const post = {
        fromDate: moment(filters.dateRange.start).format('YYYY-MM-DD'),
        toDate: moment(filters.dateRange.end).format('YYYY-MM-DD'),
        location: filters.location,
        vehicleRegistrationNumber: filters.vehicleInfo,
        paymentStatus: filters.paymentStatus,
        sms_Status: filters.sms_Status,
      };

      try {
        const data: Response = await filterFinesPDF(post);
        if (data.finesListEmpty !== 'true') {
          // Transform finesList to flatten the data
          const transformedData = data.finesList.flatMap((entry) => {
            // Include all fine fields
            const fineFields = entry.fine;

            const traillogsFields =
              entry.trailLogs && Object.keys(entry.trailLogs).length > 0
                ? entry.trailLogs
                : {};
            const offensesString = fineFields.finesOffenses
              .map(
                (offense, index) =>
                  `${index + 1}: ${offense.offenceName} - ${offense.amount}`,
              )
              .join('\n');

            return {
              fineId: fineFields.id,
              fineDate: fineFields.dateCaptured,
              totalAmount: fineFields.amount,
              speedLimit: fineFields.speedLimit,
              vehicleSpeed: fineFields.vehicleSpeed,
              vehicleType: fineFields.vehicleType,
              image: fineFields.imageUrl,
              smsStatus: fineFields.smsStatus,
              violationId: fineFields.violationId,
              fineLocation: fineFields.location,
              fineStatus: fineFields.status,
              fineSpeedLimit: fineFields.speedLimit,
              fineVehicleSpeed: fineFields.vehicleSpeed,
              fineVehicleReg: fineFields.vehicleRegistrationNumber,
              ...traillogsFields,
            };
          });

          exportPDF(transformedData);
        }
      } catch (error) {
        console.error('Error fetching fines data:', error);
      }
    }
  };

  const downloadCSV = async () => {
    if (validateDates()) {
      const post = {
        fromDate: moment(filters.dateRange.start).format('YYYY-MM-DD'),
        toDate: moment(filters.dateRange.end).format('YYYY-MM-DD'),
        location: filters.location,
        vehicleRegistrationNumber: filters.vehicleInfo,
        paymentStatus: filters.paymentStatus,
        sms_Status: filters.sms_Status,
      };
      try {
        const data: Response = await filterFinesCSV(post);
        if (data.finesListEmpty !== 'true') {
          // Transform finesList to merge fine and traillogs fields
          const transformedData = data.finesList.map((entry) => {
            const fine = entry.fine;
            // Combine all offenses into a single string with numbering
            const offensesString = fine.finesOffensesDetails
              .map(
                (offense, index) =>
                  `${index + 1}: ${offense.offenceName} - ${offense.amount}`,
              )
              .join('\n');

            // Handle trailLogs (ensure it's an array and extract its fields)
            const traillogsFields =
              Array.isArray(entry.trailLogs) && entry.trailLogs.length > 0
                ? Object.assign({}, ...entry.trailLogs) // Merge all objects in the array
                : {};

            return {
              fineId: fine.id,
              fineDate: fine.dateCaptured,
              totalAmount: fine.amount,
              speedLimit: fine.speedLimit,
              vehicleSpeed: fine.vehicleSpeed,
              vehicleType: fine.vehicleType,
              image: fine.imageUrl,
              smsStatus: fine.smsStatus,
              violationId: fine.violationId,
              fineLocation: fine.location,
              fineStatus: fine.status,
              fineSpeedLimit: fine.speedLimit,
              fineVehicleSpeed: fine.vehicleSpeed,
              fineVehicleReg: fine.vehicleRegistrationNumber,
              offenses: offensesString,
              ...traillogsFields, // Spread the merged trailLogs fields
            };
          });

          const csvData = Papa.unparse(transformedData);
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
          saveAs(blob, 'fines.csv');
        }
      } catch (error) {
        console.error('Error fetching fines data:', error);
      }
    }
  };

  const resetFilters = () => {
    setFilters({
      location: '',
      vehicleInfo: '',
      dateRange: { start: '', end: '' },
      amountRange: { min: '', max: '' },
      paymentStatus: '',
      sms_Status: '',
    });
    setIsFilterOpen(false);

    const newSearchParams = new URLSearchParams();
    newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
  };

  return {
    filters,
    setFilters,
    isFilterOpen,
    setIsFilterOpen,
    isReportOpen,
    setIsReportOpen,
    isFileOpen,
    setIsFileOpen,
    errors,
    isPendingCSV,
    isPendingPDF,
    downloadCSV,
    downloadPDF,
    resetFilters,
    validateDates,
  };
};
