import { AxiosResponse } from 'axios';
import { axiosInstance } from 'hooks/auth/AxiosInstance';

import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { TransactionsResponse } from 'utils/types';

type PageType = {
  pageSize: string;
  page: string;
  filters?: Record<string, string>;
};

type Options = {
  options: PageType;
};

const fetchData = async ({
  options: { filters = {} },
}: Options): Promise<TransactionsResponse> => {
  try {
    // Convert filters to query parameters
    const queryParams = new URLSearchParams(filters).toString();

    const response: AxiosResponse<TransactionsResponse> =
      await axiosInstance.get(
        `payment/filter_param${queryParams ? `?${queryParams}` : ''}`,
      );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

const useGetTransactions = ({
  options,
}: Options): UseQueryResult<TransactionsResponse, Error> => {
  const { page = '1', pageSize = '100', filters = {} } = options;

  return useQuery<TransactionsResponse, Error>({
    queryKey: ['transactions', page, pageSize, filters],
    queryFn: () => fetchData({ options }),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

export default useGetTransactions;
