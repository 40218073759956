import React from 'react';
import DialogLayout from 'layouts/DialogLayout';
import Button from 'components/shared/Button/Button';
import SubmitButton from 'components/shared/Button/SubmitButton';
import { Filter } from 'lucide-react';
import { FinesFilters } from 'utils/types';

interface FilterDialogProps {
  isOpen: boolean;
  onClose: () => void;
  filters: FinesFilters;
  setFilters: (filters: FinesFilters) => void;
  onReset: () => void;
}

export const FilterDialog: React.FC<FilterDialogProps> = ({
  isOpen,
  onClose,
  filters,
  setFilters,
  onReset,
}) => (
  <DialogLayout isOpen={isOpen} onClose={onClose}>
    <div className="mt-4 p-4 bg-gray-50 rounded-lg space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="mb-4">
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Location
          </label>
          <input
            type="text"
            value={filters.location}
            onChange={(e) =>
              setFilters({ ...filters, location: e.target.value })
            }
            placeholder="Search by location..."
            className="w-full px-3 py-3 text-lg border rounded-md"
          />
        </div>

        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Vehicle Information
          </label>
          <input
            type="text"
            value={filters.vehicleInfo}
            onChange={(e) =>
              setFilters({ ...filters, vehicleInfo: e.target.value })
            }
            placeholder="Search by vehicle info..."
            className="w-full px-3 py-3 text-lg border rounded-md"
          />
        </div>

        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Payment Status
          </label>
          <select
            value={filters.paymentStatus}
            onChange={(e) =>
              setFilters({ ...filters, paymentStatus: e.target.value })
            }
            className="w-full px-3 py-3 text-lg border rounded-md"
          >
            <option value="">Select an option</option>
            <option value="PAID">Paid</option>
            <option value="UNPAID">Not Paid</option>
          </select>
        </div>

        <div>
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Date Range
          </label>
          <div className="flex space-x-2">
            <input
              type="date"
              value={filters.dateRange.start}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  dateRange: { ...filters.dateRange, start: e.target.value },
                })
              }
              className="flex-1 px-3 py-3 text-lg border rounded-md"
            />
            <input
              type="date"
              value={filters.dateRange.end}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  dateRange: { ...filters.dateRange, end: e.target.value },
                })
              }
              className="flex-1 px-3 py-3 text-lg border rounded-md"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-2">
        <Button
          label="Reset"
          color="gray-600"
          backGroundColor="white"
          hoverBackgroundColor="white"
          hoverColor="gray-800"
          onClick={onReset}
        />
        <SubmitButton label="Apply Filters" Icon={Filter} />
      </div>
    </div>
  </DialogLayout>
);
