import React from 'react';
import { FileText, Plus } from 'lucide-react';
import Button from 'components/shared/Button/Button';

interface TableHeaderProps {
  isAdmin: boolean;
  onFileOpen: () => void;
  onReportOpen: () => void;
  onFilterToggle?: () => void;
  isCaseAdmin: boolean;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  isAdmin,
  onFileOpen,
  onReportOpen,
  isCaseAdmin,
}) => (
  <div className="flex justify-between items-center">
    <h2 className="text-lg font-semibold text-gray-800">Fines List</h2>
    {isAdmin && (
      <div className="flex space-x-4">
        <Button
          label="Reports"
          color="black"
          backGroundColor="gray-100"
          hoverBackgroundColor="gray-200"
          hoverColor="black"
          Icon={FileText}
          onClick={onReportOpen}
        />
      </div>
    )}
    {isCaseAdmin && (
      <div className="flex space-x-4">
        <Button
          label="Upload"
          color="white"
          backGroundColor="black"
          hoverBackgroundColor="gray-200"
          hoverColor="black"
          Icon={Plus}
          onClick={onFileOpen}
        />
      </div>
    )}
  </div>
);
