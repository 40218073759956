import React from 'react';
import { useAuth } from 'context/AuthContext';
import Table from 'components/common/table';
import { FilterDialog } from './FilterDialog';
import { ReportDialog } from './ReportDialog';
import { FileUploadDialog } from './FileUploadDialog';
import { getTableColumns } from './TableColumns';
import { Fine } from 'utils/types';
import { useFinesTable } from 'hooks/common/useFinesTable';
import { TableHeader } from './FinesTableHeader';

interface FinesTableProps {
  data: Fine[];
  onRowClick?: (fine: Fine) => void;
  pageSize?: number;
  currentPage?: number;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
  onRowUpdate?: (updatedRow: Fine) => void;
  isLoading: boolean;
  onFilter?: (columnKey: string, value: string) => void;
}

const FinesTable: React.FC<FinesTableProps> = ({
  data: initialData,
  onRowClick,
  pageSize,
  currentPage,
  totalPages,
  goToPage,
  onRowUpdate,
  isLoading,
  onFilter,
}) => {
  const { auth } = useAuth();
  const {
    filters,
    setFilters,
    isFilterOpen,
    setIsFilterOpen,
    isReportOpen,
    setIsReportOpen,
    isFileOpen,
    setIsFileOpen,
    errors,
    isPendingCSV,
    isPendingPDF,
    downloadCSV,
    downloadPDF,
    resetFilters,
  } = useFinesTable();

  const columns = getTableColumns();

  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <TableHeader
          isAdmin={auth.includes('admin')}
          isCaseAdmin={auth.includes('case admin')}
          onFileOpen={() => setIsFileOpen(true)}
          onReportOpen={() => setIsReportOpen(true)}
          onFilterToggle={() => setIsFilterOpen(!isFilterOpen)}
        />

        {isFilterOpen && (
          <FilterDialog
            isOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
            filters={filters}
            setFilters={setFilters}
            onReset={resetFilters}
          />
        )}

        {isReportOpen && (
          <ReportDialog
            isOpen={isReportOpen}
            onClose={() => setIsReportOpen(false)}
            filters={filters}
            setFilters={setFilters}
            onDownloadCSV={downloadCSV}
            onDownloadPDF={downloadPDF}
            isPendingCSV={isPendingCSV}
            isPendingPDF={isPendingPDF}
            errors={errors}
          />
        )}

        {isFileOpen && (
          <FileUploadDialog
            isOpen={isFileOpen}
            onClose={() => setIsFileOpen(false)}
          />
        )}
      </div>

      <div className="container mx-auto px-4">
        <Table
          data={initialData}
          columns={columns}
          pageSize={pageSize}
          onRowClick={onRowClick}
          currentPage={currentPage}
          goToPage={goToPage}
          totalPages={totalPages}
          onRowUpdate={onRowUpdate}
          isLoading={isLoading}
          onFilter={onFilter}
          pagination={true}
        />
      </div>
    </div>
  );
};

export default FinesTable;
