import React from 'react';
import Table, { Column } from 'components/common/table';
import { Offence } from 'utils/types';

interface ViolationsTableProps {
  data: Offence[];
  onRowClick?: (violation: Offence) => void;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
  pageSize?: number;
  currentPage?: number;
}

const ViolationsTable: React.FC<ViolationsTableProps> = ({
  data: initialData,
  onRowClick,
  goToPage,
  totalPages,
  pageSize,
  currentPage,
}) => {
  const columns: Column[] = [
    { header: 'Id', accessorKey: 'id' },
    { header: 'Violation Label', accessorKey: 'offenceLabel' },
    { header: 'Violation Title', accessorKey: 'offenceTitle' },

    {
      header: 'Fine Amount',
      accessorKey: 'amount',
    },
  ];

  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">
            Violations List
          </h2>
        </div>
      </div>
      <div className="container mx-auto px-4">
        <Table
          data={initialData}
          columns={columns}
          pageSize={pageSize}
          onRowClick={onRowClick}
          goToPage={goToPage}
          totalPages={totalPages}
          currentPage={currentPage}
          pagination={false}
        />
      </div>
    </div>
  );
};
export default ViolationsTable;
