import React from 'react';
import DialogLayout from 'layouts/DialogLayout';
import Button from 'components/shared/Button/Button';
import { Sheet, FileText } from 'lucide-react';
import { TransactionsFilters } from 'utils/types';
import { formatDateForInput } from 'utils/Functions';

interface ReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  filters: TransactionsFilters;
  setFilters: (filters: TransactionsFilters) => void;
  onDownloadCSV: () => void;
  onDownloadPDF: () => void;
  isPendingCSV: boolean;
  isPendingPDF: boolean;
  errors: { start: boolean; end: boolean };
}

export const ReportDialog: React.FC<ReportDialogProps> = ({
  isOpen,
  onClose,
  filters,
  setFilters,
  onDownloadCSV,
  onDownloadPDF,
  isPendingCSV,
  isPendingPDF,
  errors,
}) => (
  <DialogLayout isOpen={isOpen} onClose={onClose} height="85%">
    <div className="flex flex-col h-full">
      {/* Fixed Header */}
      <div className="p-8 pb-4">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Generate Report
        </h1>
        <h2 className="text-lg text-gray-600">
          Select date range and filters to download your report
        </h2>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto px-8">
        <div className="mt-4">
          <label className="block text-lg font-medium text-gray-700 mb-1">
            From
          </label>
          <input
            type="datetime-local"
            value={formatDateForInput(filters.fromDate)}
            onChange={(e) =>
              setFilters({
                ...filters,
                fromDate: new Date(e.target.value),
              })
            }
            className={`w-full px-3 py-3 text-lg border rounded-md ${
              errors.start ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.start && (
            <p className="text-red-500 text-sm mt-1">Start date is required</p>
          )}
        </div>

        <div className="mt-4">
          <label className="block text-lg mt-4 font-medium text-gray-700 mb-1">
            To
          </label>
          <input
            type="datetime-local"
            value={formatDateForInput(filters.toDate)}
            onChange={(e) =>
              setFilters({
                ...filters,
                toDate: new Date(e.target.value),
              })
            }
            className={`w-full px-3 py-3 text-lg border rounded-md ${
              errors.end ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.end && (
            <p className="text-red-500 text-sm mt-1">End date is required</p>
          )}
        </div>

        <div className="mt-4">
          <label className="block text-lg font-medium text-gray-700 mb-1">
            Payment Channel
          </label>
          <select
            value={filters.chanel}
            onChange={(e) => setFilters({ ...filters, chanel: e.target.value })}
            className="w-full px-3 py-3 text-lg border rounded-md border-gray-300"
          >
            <option value="">Select an option</option>
            <option value="mobile_money">Mobile Money</option>
            <option value="noti">Noti</option>
            <option value="card">Card</option>
            <option value="teller">Bank</option>
          </select>
        </div>
      </div>

      {/* Fixed Footer with Buttons */}
      <div className="p-8 pt-4">
        <div className="flex space-x-4 items-center justify-center">
          <Button
            label="Download as CSV"
            color="white"
            backGroundColor="black"
            hoverBackgroundColor="gray-200"
            hoverColor="black"
            Icon={Sheet}
            onClick={onDownloadCSV}
            isLoading={isPendingCSV}
          />
          <Button
            label="Download as PDF"
            color="white"
            backGroundColor="black"
            hoverBackgroundColor="gray-200"
            hoverColor="black"
            Icon={FileText}
            onClick={onDownloadPDF}
            isLoading={isPendingPDF}
          />
        </div>
      </div>
    </div>
  </DialogLayout>
);
