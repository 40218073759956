import React, { useState, useRef, MouseEvent, WheelEvent } from 'react';
import { ZoomIn, ZoomOut, RefreshCw } from 'lucide-react';

interface ZoomableImageProps {
  image?: string;
  alt?: string;
  maxZoom?: number;
}

const ZoomableImage: React.FC<ZoomableImageProps> = ({
  image,
  alt = 'zoomable image',
  maxZoom = 3,
}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const imageRef = useRef<HTMLDivElement>(null);

  const handleWheel = (e: WheelEvent<HTMLDivElement>) => {
    // Prevent default scroll behavior
    e.preventDefault();

    // Adjust zoom level (limit between 1 and maxZoom)
    const newZoomLevel = Math.max(
      1,
      Math.min(maxZoom, zoomLevel - e.deltaY * 0.001),
    );
    setZoomLevel(newZoomLevel);
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (zoomLevel > 1 && imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;
      setPosition({ x, y });
    }
  };

  const zoomIn = () => {
    setZoomLevel((prev) => Math.min(maxZoom, prev + 0.5));
  };

  const zoomOut = () => {
    setZoomLevel((prev) => Math.max(1, prev - 0.5));
  };

  const resetZoom = () => {
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  };

  return (
    <div
      className="w-full h-[190px] lg:h-[650px] overflow-hidden relative group"
      onWheel={handleWheel}
      onMouseMove={handleMouseMove}
      onMouseLeave={resetZoom}
    >
      <div
        ref={imageRef}
        className="absolute top-0 left-0 w-full h-full transition-transform duration-100 ease-out"
        style={{
          transform: `scale(${zoomLevel})`,
          transformOrigin: `${position.x}% ${position.y}%`,
        }}
      >
        <img
          src={image}
          alt={alt}
          className="h-full w-full object-cover"
          onContextMenu={(e) => e.preventDefault()} // Disable right-click
          draggable={false} // Prevent dragging
        />
      </div>

      {/* Zoom Controls */}
      <div className="absolute top-4 right-4 flex flex-col space-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <button
          onClick={zoomIn}
          className="bg-white/70 hover:bg-white/90 p-2 rounded-full shadow-md"
          disabled={zoomLevel >= maxZoom}
        >
          <ZoomIn size={20} />
        </button>
        <button
          onClick={zoomOut}
          className="bg-white/70 hover:bg-white/90 p-2 rounded-full shadow-md"
          disabled={zoomLevel <= 1}
        >
          <ZoomOut size={20} />
        </button>
        {zoomLevel !== 1 && (
          <button
            onClick={resetZoom}
            className="bg-white/70 hover:bg-white/90 p-2 rounded-full shadow-md"
          >
            <RefreshCw size={20} />
          </button>
        )}
      </div>

      {zoomLevel > 1 && (
        <div className="absolute bottom-4 right-4 bg-black/50 text-white px-2 py-1 rounded">
          Zoom: {Math.round(zoomLevel * 100)}%
        </div>
      )}
    </div>
  );
};

export default ZoomableImage;
