import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import { SendOtp } from 'utils/types';

const sendOtp = async (data: SendOtp) => {
  const response = await axiosInstance.post('fine/request-otp', data);
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useSendOtp = (): UseMutationResult<any, Error, SendOtp> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendOtp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fines'] });
    },
    onError: (error: Error) => {
      console.error('Error filtering fines:', error.message);
    },
  });
};

export default useSendOtp;
