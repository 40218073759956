/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  Fine,
  Response,
  TransactionReportResponse,
  TransactionsFilters,
} from 'utils/types';
import useDownloadTransactionsReports from 'hooks/payments/useDownloadTransactionReports';
import { toast } from 'react-toastify';

export const useTransactionsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [errors, setErrors] = useState({ start: false, end: false });

  const { mutateAsync: filterFinesCSV, isPending: isPendingCSV } =
    useDownloadTransactionsReports();
  const { mutateAsync: filterFinesPDF, isPending: isPendingPDF } =
    useDownloadTransactionsReports();

  const [filters, setFilters] = useState<TransactionsFilters>({
    fromDate: searchParams.get('fromDate')
      ? new Date(searchParams.get('fromDate')!)
      : new Date(),
    toDate: searchParams.get('toDate')
      ? new Date(searchParams.get('toDate')!)
      : new Date(),
    chanel: searchParams.get('chanel') || '',
  });

  const validateDates = () => {
    const newErrors = {
      start: !filters.fromDate,
      end: !filters.toDate,
    };
    setErrors(newErrors);
    return !newErrors.start && !newErrors.end;
  };

  const exportPDF = (data: any[]) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    doc.text('Transactions Report', 20, 10);
    autoTable(doc, {
      head: [
        [
          'Transaction Code',
          'Transaction Date',
          'Chanel',
          'Ticket Number',
          'Vehicle Type',
          'SMS Status',
          'Location',
          'Date',
          'Vehicle Registration',
          'Time',
          'Status',
          'Speed Limit',
          'Vehicle Speed',
          'Fine Amount',
        ],
      ],
      body: data.map((item) => [
        item.transactionCode,
        moment(item.transactionDateTime).format('YYYY-MM-DD hh:mm A'),
        item.chanel,
        item.fineId,
        item.vehicleType,
        item.smsStatus,
        item.fineLocation,
        moment(item.fineDate).format('YYYY-MM-DD'),
        item.fineVehicleReg,
        moment(item.fineDate).format('hh:mm A'),
        item.fineStatus,
        item.fineSpeedLimit,
        item.vehicleSpeed,
        item.totalAmount,
      ]),
      styles: { fontSize: 8 },
      columnStyles: {
        2: { cellWidth: 10 },
        6: { cellWidth: 20 },
        7: { cellWidth: 25 },
        8: { cellWidth: 20 },
      },
    });
    doc.save('Transactions.pdf');
  };

  const downloadPDF = async () => {
    if (validateDates()) {
      const post = {
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        chanel: filters.chanel,
      };

      try {
        const data: TransactionReportResponse = await filterFinesPDF(post);
        if (data.paymentsListEmpty !== 'true') {
          const transformedData = data.paymentsList.map((entry) => {
            const { fine, ...filteredFine } = entry;

            const offensesString = fine.finesOffenses
              .map(
                (offense, index) =>
                  `${index + 1}: ${offense.offenceName} - ${offense.amount}`,
              )
              .join('\n');

            return {
              transactionCode: filteredFine.transactionCode,
              transactionDateTime: filteredFine.transactionDateTime,
              chanel: filteredFine.chanel,
              fineId: fine.id,
              fineDate: fine.dateCaptured,
              totalAmount: fine.amount,
              vehicleSpeed: fine.vehicleSpeed,
              vehicleType: fine.vehicleType,
              image: fine.imageUrl,
              smsStatus: fine.smsStatus,
              violationId: fine.violationId,
              fineLocation: fine.location,
              fineStatus: fine.status,
              fineSpeedLimit: fine.speedLimit,
              fineVehicleReg: fine.vehicleRegistrationNumber,
            };
          });

          exportPDF(transformedData);
        } else {
          toast.error('No data found for the selected date range');
        }
      } catch (error) {
        console.error('Error fetching fines data:', error);
      }
    }
  };

  const downloadCSV = async () => {
    if (validateDates()) {
      const post = {
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        chanel: filters.chanel,
      };
      try {
        const data: TransactionReportResponse = await filterFinesCSV(post);
        if (data.paymentsListEmpty !== 'true') {
          const transformedData = data.paymentsList.map((entry) => {
            const { fine, ...filteredFine } = entry;

            const offensesString = fine.finesOffenses
              .map(
                (offense, index) =>
                  `${index + 1}: ${offense.offenceName} - ${offense.amount}`,
              )
              .join('\n');

            return {
              ...filteredFine,
              fineId: fine.id,
              fineDate: fine.dateCaptured,
              totalAmount: fine.amount,
              speedLimit: fine.speedLimit,
              vehicleSpeed: fine.vehicleSpeed,
              vehicleType: fine.vehicleType,
              image: fine.imageUrl,
              smsStatus: fine.smsStatus,
              violationId: fine.violationId,
              fineLocation: fine.location,
              fineStatus: fine.status,
              fineSpeedLimit: fine.speedLimit,
              fineVehicleSpeed: fine.vehicleSpeed,
              fineVehicleReg: fine.vehicleRegistrationNumber,
              offenses: offensesString,
            };
          });
          const csvData = Papa.unparse(transformedData);
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
          saveAs(blob, 'Transactions.csv');
        } else {
          toast.error('No data found for the selected date range');
        }
      } catch (error) {
        console.error('Error fetching fines data:', error);
      }
    }
  };

  const resetFilters = () => {
    setFilters({
      fromDate: new Date(),
      toDate: new Date(),
      chanel: '',
    });
    setIsFilterOpen(false);

    const newSearchParams = new URLSearchParams();
    newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
  };

  return {
    filters,
    setFilters,
    isFilterOpen,
    setIsFilterOpen,
    isReportOpen,
    setIsReportOpen,
    isFileOpen,
    setIsFileOpen,
    errors,
    isPendingCSV,
    isPendingPDF,
    downloadCSV,
    downloadPDF,
    resetFilters,
    validateDates,
  };
};
