import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'lucide-react';
import Charts from './Charts';
import { formatDateForInput, getFormattedValue } from 'utils/Functions';
import moment from 'moment';
import useGetPaymentsAnalytics from 'hooks/payments/useGetPaymentAnalytics';
import DashboardSkeleton from 'components/common/skeletons/DashboardSkeleton';
import { PaymentsAnalyticsResponse } from 'utils/types';
import MetricsCard from 'components/transactions/MetricsCard';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const MetricsDashboard = () => {
  const [fromDate, setFromDate] = useState<Date>(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  });

  const [toDate, setToDate] = useState<Date>(new Date());

  const { mutateAsync, isPending } = useGetPaymentsAnalytics();
  const [filteredFines, setFilteredFines] = useState<
    PaymentsAnalyticsResponse[]
  >([]);

  const componentRef = useRef<HTMLDivElement>(null);

  const getFilteredAnalytics = async () => {
    const post = {
      fromDate: moment(fromDate).format('YYYY-MM-DDTHH:mm'),
      toDate: moment(toDate).format('YYYY-MM-DDTHH:mm'),
    };
    try {
      const data: PaymentsAnalyticsResponse[] = await mutateAsync(post);
      setFilteredFines(data);
    } catch (error) {
      console.error('Error fetching fines data:', error);
    }
  };

  useEffect(() => {
    getFilteredAnalytics();
  }, []);

  const handleDownloadPdf = () => {
    const input = componentRef.current;
    if (!input) return;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('metrics-dashboard.pdf');
    });
  };

  if (isPending) return <DashboardSkeleton />;

  const metrics = [
    {
      title: 'Total Fines & Revenue',
      value: getFormattedValue(filteredFines[0]?.finesListAllResult, 1, true),
      subtitle: `${getFormattedValue(filteredFines[0]?.finesListAllResult, 0)} Fines`,
    },
    {
      title: 'Total Fines Paid & Revenue',
      value: getFormattedValue(filteredFines[0]?.finesListPaidResult, 1, true),
      subtitle: `${getFormattedValue(filteredFines[0]?.finesListPaidResult, 0)} Fines`,
    },
    {
      title: 'Total Fines not Paid & Revenue',
      value: getFormattedValue(
        filteredFines[0]?.finesListUnpaidResult,
        1,
        true,
      ),
      subtitle: `${getFormattedValue(filteredFines[0]?.finesListUnpaidResult, 0)} Fines`,
    },
  ];

  const barData =
    filteredFines[0]?.finesListPaidViaTeller.map((entry) => ({
      name: entry[0], // Date
      Mpesa:
        filteredFines[0]?.finesListPaidViaMpesa.find(
          (mpesa) => mpesa[0] === entry[0],
        )?.[2] || 0,
      Card:
        filteredFines[0]?.finesListPaidViaCard.find(
          (card) => card[0] === entry[0],
        )?.[2] || 0,
      Noti:
        filteredFines[0]?.finesListPaidViaNoti.find(
          (noti) => noti[0] === entry[0],
        )?.[2] || 0,
      Teller: entry[2], // Teller payments
    })) || [];

  const pieData = [
    {
      name: 'Paid',
      value: filteredFines[0]?.finesListPaidResult[0]?.[1] || 0,
      color: '#4472C4', // Blue
    },
    {
      name: 'Unpaid',
      value: filteredFines[0]?.finesListUnpaidResult[0]?.[1] || 0,
      color: '#ED7D31', // Orange
    },
    {
      name: 'Disputed',
      value: filteredFines[0]?.finesListDisputedResult[0]?.[1] || 0,
      color: '#A5A5A5', // Gray
    },
  ];
  const cameraRevenueieData = [
    {
      name: 'ARH',
      value: filteredFines[0]?.finesARH[0]?.[1] || 0,
      color: '#4472C4', // Blue
    },
    {
      name: 'Vitronics',
      value: filteredFines[0]?.finesVitronics[0]?.[1] || 0,
      color: '#ED7D31', // Orange
    },
  ];

  const aggregatePaymentMethods = () => {
    const mpesaTotal =
      filteredFines[0]?.finesListPaidViaMpesa.reduce(
        (sum, entry) => sum + (entry[2] || 0),
        0,
      ) || 0;
    const notiTotal =
      filteredFines[0]?.finesListPaidViaNoti.reduce(
        (sum, entry) => sum + (entry[2] || 0),
        0,
      ) || 0;
    const tellerTotal =
      filteredFines[0]?.finesListPaidViaTeller.reduce(
        (sum, entry) => sum + (entry[2] || 0),
        0,
      ) || 0;
    const cardTotal =
      filteredFines[0]?.finesListPaidViaCard.reduce(
        (sum, entry) => sum + (entry[2] || 0),
        0,
      ) || 0;

    return [
      {
        name: 'Mpesa',
        value: mpesaTotal,
        color: '#4472C4', // Blue
      },
      {
        name: 'Noti',
        value: notiTotal,
        color: '#ED7D31', // Orange
      },
      {
        name: 'Teller',
        value: tellerTotal,
        color: '#A5A5A5', // Gray
      },
      {
        name: 'Card',
        value: cardTotal,
        color: '#FFC000', // Yellow
      },
    ];
  };

  const paymentMethodsPieData = aggregatePaymentMethods();
  return (
    <div className="p-6">
      <div className="flex justify-end items-center mb-6">
        <div className="flex flex-col md:flex-row md:items-end gap-2 md:gap-4">
          <div className="relative">
            <label
              htmlFor="fromDate"
              className="block text-sm text-gray-500 mb-1"
            >
              From
            </label>
            <div className="relative">
              <input
                type="datetime-local"
                id="fromDate"
                value={formatDateForInput(fromDate)}
                onChange={(e) => setFromDate(new Date(e.target.value))}
                className="block w-full md:w-[180px] px-3 py-2 bg-white border border-gray-200 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-sm text-gray-600"
              />
              <Calendar className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
            </div>
          </div>

          <div className="relative">
            <label
              htmlFor="toDate"
              className="block text-sm text-gray-500 mb-1"
            >
              To
            </label>
            <div className="relative">
              <input
                type="datetime-local"
                id="toDate"
                value={formatDateForInput(toDate)}
                onChange={(e) => setToDate(new Date(e.target.value))}
                className="block w-full md:w-[180px] px-3 py-2 bg-white border border-gray-200 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-sm text-gray-600"
              />
              <Calendar className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
            </div>
          </div>

          <button
            onClick={getFilteredAnalytics}
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
            style={{ height: '42px' }}
          >
            Apply Filters
          </button>

          {/* Add a button to download the PDF */}
          <button
            onClick={handleDownloadPdf}
            className="px-4 py-2 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
            style={{ height: '42px' }}
          >
            Download PDF
          </button>
        </div>
      </div>
      <div ref={componentRef}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-6">
          {metrics.map((metric, index) => (
            <MetricsCard key={index} {...metric} />
          ))}
        </div>
        <Charts
          barData={barData}
          pieData={pieData}
          paymentMethodsPieData={paymentMethodsPieData}
          cameraRevenuePieData={cameraRevenueieData}
        />
      </div>
    </div>
  );
};

export default MetricsDashboard;
