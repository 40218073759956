import React, { useEffect } from 'react';
import './App.css';
import { SidebarProvider } from 'context/SideBarContext';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from 'pages/dashboard/Dashboard';
import Sms from 'pages/sms';
import ProtectedRoute from 'ProtectedRoute';
import Login from 'pages/auth/Login';
import { AuthProvider } from 'context/AuthContext';
import Profile from 'pages/profile';
import Fines from 'pages/Fines';
import FineDetails from 'pages/Fines/SingleFine';
import Violations from 'pages/Violations';
import MainLayout from 'layouts/MainLayout';
import { Bounce, ToastContainer } from 'react-toastify';
import { useKeycloak } from '@react-keycloak/web';
import { initSentry } from 'utils/sentry';
import * as Sentry from '@sentry/react';
import '@radix-ui/themes/styles.css';
import Finance from 'pages/finance';
import Revenue from 'pages/finance/Revenue';
import Reports from 'pages/finance/Reports';
import { FinesProvider } from 'context/FinesContext';
import FineViewAuth from 'pages/auth/FineViewAuth';

function App() {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    initSentry(keycloak);
  }, []);

  const getFallbackPath = () => {
    const authType = localStorage.getItem('authType');
    if (authType === 'regular') {
      const ticketNo = localStorage.getItem('ticketNo');
      return ticketNo ? `/case/${ticketNo}` : '/auth/login';
    } else {
      return '/auth/login';
    }
  };

  return (
    <AuthProvider>
      <SidebarProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
        <Routes>
          <Route element={<ProtectedRoute fallbackPath={getFallbackPath()} />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/fines/*"
                element={
                  <FinesProvider>
                    <Routes>
                      <Route path=":page" element={<Fines />} />
                      <Route path="single/:id" element={<FineDetails />} />
                      <Route
                        index
                        element={<Navigate to="/fines/1" replace />}
                      />
                    </Routes>
                  </FinesProvider>
                }
              />

              <Route path="/sms" element={<Sms />} />
              <Route path="/violations/:page" element={<Violations />} />
              <Route
                path="/violations"
                element={<Navigate to="/violations/1" replace />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/finance/overview" element={<Finance />} />
              <Route path="/finance/revenue" element={<Revenue />} />
              <Route path="/finance/transactions/:page" element={<Reports />} />
              <Route
                path="/finance/transactions"
                element={<Navigate to="/finance/transactions/1" replace />}
              />
            </Route>
          </Route>

          {/* Public Routes */}
          <Route path="/case/:ticketNo" element={<FineViewAuth />} />
          <Route path="/auth/login" element={<Login />} />
        </Routes>
      </SidebarProvider>
    </AuthProvider>
  );
}

export default Sentry.withProfiler(App);
