import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';

type MetricsCardProps = {
  title: string;
  value: string;
  change: string;
  subtitle: string;
  isPositive: boolean;
};
const MetricsCard = ({
  title,
  value,
  change,
  subtitle,
  isPositive,
}: MetricsCardProps) => {
  const changeValue = Math.abs(Number(change));

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
      <div className="space-y-2">
        <p className="text-sm text-gray-600">{title}</p>

        <div className="flex items-center space-x-2">
          <span className="text-2xl font-semibold">{value}</span>
          <span
            className={`flex items-center text-sm ${isPositive ? 'text-green-600' : 'text-red-600'}`}
          >
            {isPositive ? (
              <TrendingUp size={16} className="mr-1" />
            ) : (
              <TrendingDown size={16} className="mr-1" />
            )}
            {changeValue}%
          </span>
        </div>

        <p className="text-sm text-gray-500">{subtitle}</p>
      </div>
    </div>
  );
};

const Revenue = () => {
  const metrics = [
    {
      title: 'Total Fines Enforced & Revenue',
      value: 'KES 4,250',
      change: '+8',
      subtitle: '35 Fines',
      isPositive: true,
    },
    {
      title: 'Total Fines not Enforced & Revenue',
      value: 'KES 4,250',
      change: '+8',
      subtitle: '35 Fines',
      isPositive: true,
    },

    {
      title: 'Total Fines Disputed & Revenue',
      value: 'KES 4,250',
      change: '+8',
      subtitle: '35 Fines',
      isPositive: true,
    },
    {
      title: 'Total Fines Paid via Teller & Revenue',
      value: 'KES 4,250',
      change: '+8',
      subtitle: '35 Fines',
      isPositive: true,
    },
    {
      title: 'Total Fines Paid via Noti & Revenue',
      value: 'KES 4,250',
      change: '+8',
      subtitle: '35 Fines',
      isPositive: true,
    },
    {
      title: 'Total Fines Paid via Mpesa & Revenue',
      value: 'KES 4,250',
      change: '+8',
      subtitle: '35 Fines',
      isPositive: true,
    },
    {
      title: 'Total Fines Paid via Card & Revenue',
      value: 'KES 4,250',
      change: '+8',
      subtitle: '35 Fines',
      isPositive: true,
    },
  ];

  return (
    <div className="p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map((metric, index) => (
          <MetricsCard key={index} {...metric} />
        ))}
      </div>
    </div>
  );
};

export default Revenue;
