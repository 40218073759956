/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Fine } from 'utils/types';
import moment from 'moment';
// import { Briefcase, Phone } from 'lucide-react';
import NoDataHandling from 'components/shared/NoData/NoDataHandling';
import TabbedInterface from 'components/fines/TabbedInterface';
interface InfoItemProps {
  label: string;
  value?: string | number;
}

type Props = {
  fine?: Fine;
};

const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => (
  <div className="space-y-1">
    <p className="text-sm text-gray-500">{label}</p>
    <p className="font-medium">{value}</p>
  </div>
);

const FineView = ({ fine }: Props) => {
  // const [phoneNumber, setPhoneNumber] = useState('');
  // const { mutateAsync, isPending } = useConfirmPayment();
  // const [showConfirmation, setShowConfirmation] = useState(false);
  // const [confirmationData, setConfirmationData] =
  //   useState<FineConfirmation | null>(null);
  if (!fine)
    return (
      <NoDataHandling content="No fine details found for the specified ID." />
    );

  // const handlePayment = async () => {
  //   if (!phoneNumber) {
  //     toast.error('Please enter a phone number', {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'light',
  //       transition: Bounce,
  //     });
  //     return;
  //   }

  //   try {
  //     const post = {
  //       userID: phoneNumber,
  //       ticketNumber: fine.id.toString(),
  //     };
  //     await mutateAsync(post)
  //       .then((data) => setConfirmationData(data))
  //       .then(() => setShowConfirmation(true))
  //       .catch(() =>
  //         toast.error('There was an error initiating the payment', {
  //           position: 'top-right',
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: false,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: 'light',
  //           transition: Bounce,
  //         }),
  //       );
  //   } catch (error: any) {
  //     alert(error);
  //   }
  // };

  const tabs = [
    {
      label: 'Details',
      content: (
        <>
          <div className="w-full mb-5 lg:h-[900px] md:h-[400px] h-44 overflow-hidden relative">
            <img
              src={fine.imageUrl}
              alt="Traffic violation capture"
              className="w-full h-full object-cover object-center"
              onContextMenu={(e) => e.preventDefault()} // Disable right-click
              draggable={false} // Prevent dragging
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <InfoItem
              label="Vehicle Registration"
              value={fine.vehicleRegistrationNumber}
            />
            <InfoItem label="Location" value={fine.location} />
            <InfoItem label="Speed Limit" value={fine.speedLimit} />
            <InfoItem label="Vehicle Speed" value={fine.vehicleSpeed} />
          </div>
        </>
      ),
    },
    {
      label: 'Infractions',
      content: (
        <div>
          {fine.offenses !== undefined &&
            fine.offenses.length > 0 &&
            fine.offenses.map((offence) => (
              <div
                key={offence.id}
                className="bg-gray-50 rounded-lg p-4 mb-4 last:mb-0 border border-gray-200"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <InfoItem
                    label="Infraction Time"
                    value={moment(fine.dateCaptured).format(
                      'Do MMMM YYYY h:mm a',
                    )}
                  />
                  <InfoItem label="Traffic Act" value={offence.sectionAct} />
                  <InfoItem
                    label="Infraction Title"
                    value={offence.offenceName}
                  />
                  {/* <InfoItem
                    label="Infraction Amount"
                    value={`${offence.amount.toLocaleString()}`}
                  /> */}
                </div>
              </div>
            ))}
        </div>
      ),
    },
    // {
    //   label: 'Payment',
    //   content: (
    //     <div className="space-y-8 h-screen">
    //       <div className="bg-gray-50 p-4 sm:p-6 rounded-lg border border-gray-200">
    //         <div className="flex items-center space-x-4 mb-6">
    //           <img src={Mpesa} alt="M-Pesa" className="h-8 sm:h-10 w-auto" />
    //           <div>
    //             <h3 className="font-semibold text-lg text-gray-800">
    //               Pay with M-Pesa
    //             </h3>
    //             <p className="text-sm text-gray-500">Fast and secure payment</p>
    //           </div>
    //         </div>

    //         <div className="space-y-4">
    //           <div>
    //             <label className="block text-sm text-gray-600 mb-2">
    //               Phone Number
    //             </label>
    //             <input
    //               type="text"
    //               placeholder="0712 345 678"
    //               value={phoneNumber}
    //               onChange={(e) => setPhoneNumber(e.target.value)}
    //               className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg focus:ring-2 focus:ring-ntsaGreen focus:border-transparent outline-none"
    //             />
    //           </div>
    //           <div className="pt-2">
    //             <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-4 sm:space-y-0">
    //               <div className="text-gray-600">
    //                 Amount:{' '}
    //                 <span className="font-semibold text-lg text-gray-900">
    //                   {(fine.offenses &&
    //                   Array.isArray(fine.offenses) &&
    //                   fine.offenses.length > 0
    //                     ? fine.offenses.reduce(
    //                         (sum, item) => sum + item.amount,
    //                         0,
    //                       )
    //                     : 0
    //                   ).toLocaleString()}
    //                 </span>
    //               </div>
    //               <button
    //                 onClick={handlePayment}
    //                 disabled={isPending}
    //                 className="w-full sm:w-auto px-6 py-3 bg-ntsaGreen text-white rounded-lg hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed font-medium text-base flex items-center justify-center space-x-2"
    //               >
    //                 <img
    //                   src="/mpesa-white-logo.png"
    //                   alt=""
    //                   className="h-5 w-auto"
    //                 />
    //                 <span>{isPending ? 'Processing...' : 'Pay Now'}</span>
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       {/* <div className="relative">
    //         <div className="absolute inset-0 flex items-center">
    //           <div className="w-full border-t border-gray-200"></div>
    //         </div>
    //         <div className="relative flex justify-center">
    //           <span className="px-4 bg-white text-sm text-gray-500">or</span>
    //         </div>
    //       </div>
    //       <div className="bg-gray-50 p-4 sm:p-6 rounded-lg border border-gray-200">
    //         <h3 className="font-semibold text-lg mb-4 text-gray-800">
    //           Pay using USSD:
    //         </h3>
    //         <ol className="space-y-4">
    //           <li className="flex items-start space-x-3">
    //             <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-ntsaGreen text-white rounded-full text-sm">
    //               1
    //             </span>
    //             <div className="flex">
    //               <span className="block">Dial</span>
    //               <span className="inline-block font-mono text-lg px-4  rounded border  font-semibold text-ntsaGreen">
    //                 *396#
    //               </span>
    //             </div>
    //           </li>
    //           <li className="flex items-start space-x-3">
    //             <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-ntsaGreen text-white rounded-full text-sm">
    //               2
    //             </span>
    //             <span className="flex-1">Select option for Pay Fine</span>
    //           </li>
    //           <li className="flex items-start space-x-3">
    //             <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-ntsaGreen text-white rounded-full text-sm">
    //               3
    //             </span>
    //             <div className="flex">
    //               <span className="block">Enter Ticket Number</span>
    //               <span className="inline-block font-mono text-lg bg-white mt-1 px-4 py-2 rounded border border-gray-200 font-semibold text-ntsaGreen">
    //                 {fine.id.toString()?.slice(-6).toUpperCase()}
    //               </span>
    //             </div>
    //           </li>
    //           <li className="flex items-start space-x-3">
    //             <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-ntsaGreen text-white rounded-full text-sm">
    //               4
    //             </span>
    //             <div className="flex-1">
    //               <span className="block">Confirm Amount</span>
    //               <span className="inline-block font-semibold text-lg mt-1">
    //                 KES xxxxxx
    //               </span>
    //             </div>
    //           </li>
    //           <li className="flex items-start space-x-3">
    //             <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-ntsaGreen text-white rounded-full text-sm">
    //               5
    //             </span>
    //             <span className="flex-1">
    //               Enter your M-Pesa PIN to complete
    //             </span>
    //           </li>
    //         </ol>
    //       </div> */}
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div className="p-6 space-y-6">
        {/* {showConfirmation && (
          <ConfirmationModal
            confirmationData={confirmationData}
            onClose={() => setShowConfirmation(false)}
            id={fine.id}
            phoneNumber={phoneNumber}
          />
        )} */}
        <div className="px-6 py-4 bg-ntsaGreen border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-xl text-white font-semibold">
              Ticket #{fine.ticketNumber.toString()?.slice(-6).toUpperCase()}
            </h2>
            <p className="text-sm font-bold text-white">
              Issued on{' '}
              {moment(fine.dateCaptured).format('Do MMMM YYYY h:mm a')}
            </p>
          </div>
        </div>
        <TabbedInterface tabs={tabs} />
      </div>
    </>
  );
};

export default FineView;
