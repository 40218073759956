/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

export const initSentry = (keycloak: any) => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
    beforeSend(event) {
      return event;
    },
  });

  if (keycloak?.authenticated) {
    Sentry.setUser({
      id: keycloak.subject ?? undefined,
      username: keycloak.tokenParsed?.preferred_username,
      email: keycloak.tokenParsed?.email,
    });
  }
};

export const captureException = (
  error: Error,
  context?: Record<string, any>,
) => {
  Sentry.withScope((scope) => {
    if (context) {
      scope.setExtras(context);
    }
    Sentry.captureException(error);
  });
};
