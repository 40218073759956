/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Briefcase,
  Car,
  MessageCircle,
  Plus,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';
import NoDataHandling from 'components/shared/NoData/NoDataHandling';
import useGetSingleFine from 'hooks/fines/useGetSingleFine';
import SingleViewLayout from 'layouts/SingleViewLayout';
import { useParams, useNavigate } from 'react-router-dom';
import { getBackgroundColor } from 'utils/Functions';
import useGetTrailLogs from 'hooks/fines/useGetFineTrailLogs';
import moment from 'moment';
import FineDetailsSkeleton from 'components/common/skeletons/FineDetailsSkeleton';
import DialogLayout from 'layouts/DialogLayout';
import MultiSelect from 'components/fines/MultiSelectComponent';
import useAddInfraction from 'hooks/fines/useAddInfraction';
import { useAuth } from 'context/AuthContext';
import { Bounce, toast } from 'react-toastify';
import { useKeycloak } from '@react-keycloak/web';
import ZoomableImage from 'components/common/ZoomableImage';
import { useFines } from 'context/FinesContext';

interface InfoItemProps {
  label: string;
  value?: string | number;
}
const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => (
  <div className="space-y-1">
    <p className="text-sm text-gray-500">{label}</p>
    <p className="font-medium">{value}</p>
  </div>
);
const FineDetails: React.FC = () => {
  const { auth } = useAuth();
  const { keycloak } = useKeycloak();
  const { id } = useParams<{ id: string }>();
  const { data: fine, isLoading: fineLoading } = useGetSingleFine(id || '');
  const { mutateAsync, isPending } = useGetTrailLogs();
  const [trailLog, setTrailLog] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [infractions, setInfractions] = useState<string[]>();
  const { mutateAsync: infractionMutation, isPending: infractionPending } =
    useAddInfraction();
  const { fetchNextFine, fetchPreviousFine } = useFines();
  const navigate = useNavigate();

  const handleNextFine = async () => {
    if (!id) return;
    const nextFine = await fetchNextFine(id);
    if (nextFine) {
      navigate(`/fines/single/${nextFine.id}`);
    }
  };

  const handlePreviousFine = async () => {
    if (!id) return;
    const previousFine = await fetchPreviousFine(id);
    if (previousFine) {
      navigate(`/fines/single/${previousFine.id}`);
    }
  };

  const sampleOptions = [
    { value: '8', label: 'Driving while using a mobile phone' },
    {
      value: '18',
      label: 'Failure to wear a seat belt while the vehicle is in motion',
    },
    {
      value: '26',
      label: 'Travelling with a part of the body outside a moving vehicle',
    },
  ];

  const handleChange = (selectedValues: string[]) => {
    setInfractions(selectedValues);
  };
  const getTrailLogs = async () => {
    await mutateAsync({ id: id ?? '' }).then((data) => {
      if (data.trailLog) {
        setTrailLog(data.trailLog);
      }
    });
  };
  useEffect(() => {
    getTrailLogs();
  }, []);
  if (fineLoading || isPending) return <FineDetailsSkeleton />;

  if (!fine)
    return (
      <NoDataHandling content="No fine details found for the specified ID." />
    );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const post = {
      ticketNumber: fine.id.toString(),
      vehicleRegistrationNumber: '',
      offences: infractions,
      userId: keycloak?.tokenParsed.sub,
    };

    await infractionMutation(post)
      .then(() =>
        toast.success('The infractions were added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        }),
      )
      .then(() => setIsOpen(false))
      .catch(() =>
        toast.error('There was an error adding the infractions', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        }),
      );
  };

  return (
    <SingleViewLayout title="Fine Details">
      <div className="flex justify-between mb-4">
        <button
          onClick={handlePreviousFine}
          className="px-4 py-2 bg-gray-200 rounded-lg flex items-center space-x-2"
        >
          <ChevronLeft size={16} />
          <span>Previous</span>
        </button>
        <button
          onClick={handleNextFine}
          className="px-4 py-2 bg-gray-200 rounded-lg flex items-center space-x-2"
        >
          <span>Next</span>
          <ChevronRight size={16} />
        </button>
      </div>
      {isOpen && (
        <DialogLayout height="70%" isOpen onClose={() => setIsOpen(false)}>
          <div className="p-6 space-y-6">
            {/* Title */}
            <div className="text-center mb-8">
              <h2 className="text-2xl font-bold text-gray-800">
                Add Vehicle Infraction
              </h2>
              <p className="text-gray-600 text-sm mt-2">
                Fill out the details below to add a new infraction to a vehicle.
              </p>
            </div>

            {/* Form */}
            <form className="space-y-6 " onSubmit={onSubmit}>
              {/* Vehicle Registration Number */}
              <div>
                <label
                  htmlFor="vehicle-reg"
                  className="block text-lg font-medium text-gray-700 mb-2"
                >
                  Vehicle Registration Number
                </label>
                <input
                  id="vehicle-reg"
                  type="text"
                  disabled
                  value={fine.vehicleRegistrationNumber}
                  placeholder="Enter registration number"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="infractions"
                  className="block text-lg font-medium text-gray-700 mb-2"
                >
                  Infraction(s) to be Added
                </label>
                <MultiSelect
                  options={sampleOptions}
                  onChange={handleChange}
                  placeholder="Select infraction(s)"
                  className="w-full"
                />
              </div>

              <div className="flex justify-center mt-6">
                <button
                  type="submit"
                  className="px-6 py-3 bg-blue-600 text-white font-medium text-lg rounded-lg shadow-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {infractionPending ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </DialogLayout>
      )}
      <div className="lg:p-6 space-y-6">
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="lg:px-6 px-2 py-4 border-b border-gray-200 flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold">
                Ticket #{fine?.ticketNumber.toString().slice(-6).toUpperCase()}
              </h2>
            </div>
            <span
              className={`px-4 py-1 rounded-full text-sm font-medium
                ${
                  fine.status === 'PAID'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
                }`}
            >
              {fine.status === 'PAID' ? 'Paid' : 'Not paid'}
            </span>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <InfoItem
                label="Vehicle Registration"
                value={fine.vehicleRegistrationNumber}
              />
              <InfoItem label="Location" value={fine.location} />
              <InfoItem label="Vehicle Type" value={fine.vehicleType} />
            </div>
          </div>
        </div>
        {/* Other offences */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex items-center space-x-3">
              <Briefcase className="h-6 w-6 text-gray-600" />
              <h2 className="text-xl font-bold text-gray-800">Infractions</h2>
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
            {fine.finesOffenses !== undefined &&
              fine.finesOffenses.length > 0 &&
              fine.finesOffenses.map((offence) => (
                <div
                  key={offence.id}
                  className="bg-gray-50 rounded-lg p-4 mb-4 last:mb-0 border border-gray-200"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* <InfoItem label="Infraction Id" value={offence.id} /> */}
                    <InfoItem
                      label="Infraction Time"
                      value={moment(fine.dateCaptured).format(
                        'Do MMMM YYYY h:mm a',
                      )}
                    />

                    <InfoItem
                      label="Infraction Amount"
                      value={`${offence.amount.toLocaleString()}`}
                    />
                    <InfoItem
                      label="Infraction Title"
                      value={offence.offenceName}
                    />
                  </div>
                </div>
              ))}
            <p className="text-right text-lg font-semibold">
              Total Amount:{' '}
              {(fine.finesOffenses &&
              Array.isArray(fine.finesOffenses) &&
              fine.finesOffenses.length > 0
                ? fine.finesOffenses.reduce((sum, item) => sum + item.amount, 0)
                : 0
              ).toLocaleString()}
            </p>
          </div>
        </div>
        {/* sms details */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex space-x-2 items-center">
                <MessageCircle className="h-5 w-5 text-gray-500" />
                <h2 className="text-lg font-semibold">SMS Details</h2>
              </div>
              <span
                className={` ${fine.smsStatus === 'P' || fine.smsStatus === null ? 'text-black' : 'text-white'} text-black px-3 py-1 rounded-3xl justify-center flex items-center ${getBackgroundColor(fine.smsStatus)}`}
              >
                {fine.smsStatus === null
                  ? 'No status'
                  : fine.smsStatus === 'S'
                    ? 'Sent'
                    : fine.smsStatus === 'P'
                      ? 'Pending'
                      : 'Failed'}
              </span>
            </div>
          </div>
          {trailLog !== undefined ? (
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoItem label="Trail Id" value={trailLog[0].trailId} />
                <InfoItem
                  label="Trail Time"
                  value={moment(trailLog[0].trailTime).format(
                    'Do MMMM YYYY h:mm a',
                  )}
                />
                <InfoItem label="Trail Comment" value={trailLog[0].comment} />
              </div>
            </div>
          ) : (
            <div className="p-6 text-center text-gray-500">
              No trail for this case was found
            </div>
          )}
        </div>
        {/* Owner Details Card */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex items-center gap-2">
              <Car className="h-5 w-5 text-gray-500" />
              <h2 className="text-lg font-semibold">Owner Details</h2>
            </div>
          </div>
          {fine.ownerDetails === null ? (
            <div className="p-6">Owner details not found</div>
          ) : (
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoItem label="Name" value={fine.ownerDetails.name} />
                <InfoItem
                  label="Phone Number"
                  value={fine.ownerDetails.phoneNumber}
                />
                <InfoItem
                  label="Vehicle Use"
                  value={fine.ownerDetails.vehicleUse}
                />
                <InfoItem label="Vehicle Type" value={fine.vehicleType} />
              </div>
            </div>
          )}
        </div>

        <ZoomableImage image={fine.imageUrl} alt="fine image" maxZoom={4} />
        {auth.includes('case processing accessor') && (
          <button
            onClick={() => setIsOpen(true)}
            className="px-6 text-white py-2 flex space-x-4 items-center bg-blue-500 rounded-lg transition-colors"
          >
            <Plus size={16} />
            Add an additional infraction
          </button>
        )}
      </div>
    </SingleViewLayout>
  );
};

export default FineDetails;
