/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TableHeader } from './TableHeader';
import { TablePagination } from './TablePagination';
import { TableRow } from './TableRow';
import { useAuth } from 'context/AuthContext';
import { getNestedValue } from 'utils/Functions';
interface FilterOption {
  value: string;
  label: string;
}
export interface Column {
  header: string;
  accessorKey: string;
  cell?: (value: any) => React.ReactNode;
  minWidth?: string;
  editable?: boolean;
  editComponent?: React.ComponentType<{
    value: any;
    onChange: (value: any) => void;
    onBlur?: () => void;
  }>;
  thumbnail?: {
    width?: number;
    height?: number;
  };
  filterable?: boolean;
  filterType?: 'text' | 'select' | 'date';
  filterOptions?: FilterOption[];
}

interface TableProps<T> {
  data: T[];
  columns: Column[];
  pageSize?: number;
  currentPage?: number;
  title?: string;
  onRowClick?: (row: T) => void;
  onRowUpdate?: (updatedRow: T) => void;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
  isLoading?: boolean;
  onFilter?: (columnKey: string, value: string) => void;
  pagination: boolean;
}

const Table = <T extends object>({
  data,
  columns,
  pageSize = 10,
  currentPage = 1,
  title,
  onRowClick,
  onRowUpdate,
  totalPages,
  goToPage,
  isLoading,
  onFilter,
  pagination,
}: TableProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [editedRowData, setEditedRowData] = useState<Partial<T> | null>(null);

  // Extract sorting parameters from the URL
  const sortColumn = searchParams.get('sortColumn') || '';
  const sortDirection = searchParams.get('sortDirection') || 'desc';
  const { auth } = useAuth();
  const sortData = (
    dataToSort: T[],
    column: string,
    direction: 'asc' | 'desc',
  ) => {
    if (!column) return dataToSort;

    return [...dataToSort].sort((a, b) => {
      const aValue = getNestedValue(a, column);
      const bValue = getNestedValue(b, column);

      const isDateColumn = columns.some(
        (col) =>
          col.accessorKey === column &&
          col.header.toLowerCase().includes('date'),
      );

      if (isDateColumn) {
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);
        return direction === 'asc'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      if (direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });
  };

  const sortedData = useMemo(() => {
    return sortColumn
      ? sortData(data, sortColumn, sortDirection as 'asc' | 'desc')
      : data;
  }, [data, sortColumn, sortDirection]);

  const handleSort = (columnKey: string) => {
    const newDirection =
      sortColumn === columnKey && sortDirection === 'asc' ? 'desc' : 'asc';
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('sortColumn', columnKey);
    newSearchParams.set('sortDirection', newDirection);
    newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
  };

  const handleEditStart = (rowIndex: number) => {
    setEditingRow(rowIndex);
    setEditedRowData({ ...data[rowIndex] });
  };

  const handleEditCancel = () => {
    setEditingRow(null);
    setEditedRowData(null);
  };

  const handleEditSave = () => {
    if (editingRow !== null && editedRowData && onRowUpdate) {
      onRowUpdate(editedRowData as T);
      setEditingRow(null);
      setEditedRowData(null);
    }
  };

  const handleFieldChange = (key: string, value: any) => {
    if (editedRowData) {
      setEditedRowData({
        ...editedRowData,
        [key]: value,
      });
    }
  };

  return (
    <div className="w-full bg-white rounded-lg shadow">
      {/* Title */}
      {title && (
        <div className="px-4 sm:px-6 py-4 border-b">
          <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
        </div>
      )}

      {/* Table */}
      <div className="w-full overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <TableHeader
            columns={columns}
            sortColumn={sortColumn}
            sortDirection={sortDirection as 'asc' | 'desc'}
            handleSort={handleSort}
            onRowUpdate={onRowUpdate}
            onFilter={onFilter}
          />
          <tbody className="divide-y divide-gray-200">
            {sortedData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                row={row}
                columns={columns}
                rowIndex={rowIndex}
                editingRow={editingRow}
                editedRowData={editedRowData}
                isLoading={isLoading}
                onRowClick={onRowClick}
                isProcessor={auth.includes('case processing accessor')}
                handleEditStart={handleEditStart}
                handleEditCancel={handleEditCancel}
                handleEditSave={handleEditSave}
                handleFieldChange={handleFieldChange}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {pagination && (
        <TablePagination
          currentPage={currentPage}
          totalPages={totalPages}
          pageSize={pageSize}
          dataLength={sortedData.length}
          goToPage={goToPage}
        />
      )}
    </div>
  );
};

export default Table;
