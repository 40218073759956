/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/return-await */

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useGetFines from 'hooks/fines/useGetFines';
import { Fine } from 'utils/types';

interface FinesContextType {
  currentPage: number;
  pageSize: number;
  fines: Fine[];
  totalPages: number;
  isLoading: boolean;
  goToPage: (pageNumber: number) => void;
  handleFilter: (columnKey: string, value: string) => void;
  fetchNextFine: (currentFineId: string) => Promise<Fine | null>;
  fetchPreviousFine: (currentFineId: string) => Promise<Fine | null>;
}

const FinesContext = createContext<FinesContextType | undefined>(undefined);

export const FinesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page') || '1', 10),
  );

  const initialFilters = {
    smsStatus: searchParams.get('smsStatus') || '',
    violationId: searchParams.get('violationId') || '',
    vehicleRegistrationNumber:
      searchParams.get('vehicleRegistrationNumber') || '',
    location: searchParams.get('location') || '',
    id: searchParams.get('id') || '',
    dateCaptured: searchParams.get('dateCaptured') || '',
  };

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1', 10);
    setCurrentPage(page);
  }, [searchParams]);

  const cleanFilters = (filtersParams: Record<string, string>) => {
    return Object.fromEntries(
      Object.entries(filtersParams).filter(([_, value]) => value !== ''),
    );
  };

  const { data: fines, isLoading } = useGetFines({
    options: {
      page: currentPage.toString(),
      pageSize: pageSize.toString(),
      filters: cleanFilters(filters),
    },
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageNumber: (currentPage - 1).toString(),
      pageSize: pageSize.toString(),
    }));
  }, [currentPage]);

  const goToPage = (pageNumber: number) => {
    if (
      fines !== undefined &&
      pageNumber > 0 &&
      pageNumber <= fines.totalPages
    ) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', pageNumber.toString());
      setSearchParams(newSearchParams);
    }
  };

  const handleFilter = (columnKey: string, value: string) => {
    const newFilters = { ...filters, [columnKey]: value };
    setFilters(newFilters);

    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(columnKey, value);
    } else {
      newSearchParams.delete(columnKey);
    }
    setSearchParams(newSearchParams);
  };

  // Refs to access up-to-date state in asynchronous functions
  const currentPageRef = useRef(currentPage);
  const isLoadingRef = useRef(isLoading);
  const finesRef = useRef(fines);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    finesRef.current = fines;
  }, [fines]);

  // Helper function to wait for new page data to be loaded
  const waitForPage = (expectedPage: number): Promise<Fine | null> => {
    return new Promise((resolve) => {
      const check = () => {
        if (
          !isLoadingRef.current &&
          currentPageRef.current === expectedPage &&
          finesRef.current &&
          finesRef.current.finesList.length > 0
        ) {
          // Return the first fine on the new page for fetchNextFine
          resolve(finesRef.current.finesList[0]);
        } else {
          setTimeout(check, 100);
        }
      };
      check();
    });
  };

  const fetchNextFine = async (currentFineId: string): Promise<Fine | null> => {
    const currentIndex = fines?.finesList.findIndex(
      (fine) => fine.id.toString() === currentFineId,
    );
    if (
      currentIndex !== undefined &&
      currentIndex !== -1 &&
      fines &&
      currentIndex < fines.finesList.length - 1
    ) {
      return fines.finesList[currentIndex + 1];
    }

    if (fines && currentPage < fines.totalPages) {
      const nextPage = currentPage + 1;
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', nextPage.toString());
      setSearchParams(newSearchParams);
      return await waitForPage(nextPage);
    }

    return null;
  };

  const fetchPreviousFine = async (
    currentFineId: string,
  ): Promise<Fine | null> => {
    const currentIndex = fines?.finesList.findIndex(
      (fine) => fine.id.toString() === currentFineId,
    );
    if (
      currentIndex !== undefined &&
      fines &&
      currentIndex !== -1 &&
      currentIndex > 0
    ) {
      return fines.finesList[currentIndex - 1];
    }

    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', previousPage.toString());
      setSearchParams(newSearchParams);
      // Wait for the new page to load; here you might choose to return the last item of the previous page
      return await new Promise((resolve) => {
        const check = () => {
          if (
            !isLoadingRef.current &&
            currentPageRef.current === previousPage &&
            finesRef.current &&
            finesRef.current.finesList.length > 0
          ) {
            resolve(
              finesRef.current.finesList[finesRef.current.finesList.length - 1],
            );
          } else {
            setTimeout(check, 100);
          }
        };
        check();
      });
    }

    return null;
  };

  return (
    <FinesContext.Provider
      value={{
        currentPage,
        pageSize,
        fines: fines?.finesList || [],
        totalPages: fines?.totalPages || 0,
        isLoading,
        goToPage,
        handleFilter,
        fetchNextFine,
        fetchPreviousFine,
      }}
    >
      {children}
    </FinesContext.Provider>
  );
};

export const useFines = () => {
  const context = useContext(FinesContext);
  if (context === undefined) {
    throw new Error('useFines must be used within a FinesProvider');
  }
  return context;
};
