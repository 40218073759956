import TableSkeleton from 'components/common/skeletons/TableSkeleton';
import SmsTable from 'components/sms/SmsTable';
import useGetFines from 'hooks/fines/useGetFines';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Sms = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page') || '1', 10),
  );

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1', 10);
    setCurrentPage(page);
  }, [searchParams]);

  const { data: fines, isLoading: finesLoading } = useGetFines({
    options: { page: currentPage.toString(), pageSize: pageSize.toString() },
  });

  const goToPage = (pageNumber: number) => {
    if (
      fines !== undefined &&
      pageNumber > 0 &&
      pageNumber <= fines.totalPages
    ) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', pageNumber.toString());
      setSearchParams(newSearchParams);
    }
  };
  if (finesLoading) return <TableSkeleton />;
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <SmsTable
        data={fines?.finesList ?? []}
        onRowClick={(fine) => navigate(`/fines/single/${fine.id}`)}
        goToPage={goToPage}
        totalPages={fines?.totalPages || 0}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  );
};

export default Sms;
