import { axiosInstance } from 'hooks/auth/AxiosInstance';

type PaymentParams = {
  fromDate: string;
  toDate: string;
};

import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';

const getPayment = async (data: PaymentParams) => {
  const response = await axiosInstance.post('payment/analytics', data);
  return response.data;
};

const useGetPaymentsAnalytics = (): UseMutationResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Error,
  PaymentParams
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: getPayment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['paymentsanalytics'] });
    },
    onError: (error: Error) => {
      console.error('Error making payment:', error.message);
    },
  });
};

export default useGetPaymentsAnalytics;
