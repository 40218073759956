import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown, Filter } from 'lucide-react';
import { Column } from '.';
import { useSearchParams } from 'react-router-dom';

interface TableHeaderProps<T> {
  columns: Column[];
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
  handleSort: (columnKey: string) => void;
  onRowUpdate?: (updatedRow: T) => void;
  onFilter?: (columnKey: string, value: string) => void;
}

export const TableHeader = <T extends object>({
  columns,
  sortColumn,
  sortDirection,
  handleSort,
  onRowUpdate,
  onFilter,
}: TableHeaderProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilter, setShowFilter] = useState<Record<string, boolean>>({});
  const [textInputValues, setTextInputValues] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    const updatedTextInputs: Record<string, string> = {};
    columns.forEach((column) => {
      const paramValue = searchParams.get(column.accessorKey);
      if (paramValue) {
        updatedTextInputs[column.accessorKey] = paramValue;
        onFilter?.(column.accessorKey, paramValue);
      } else {
        updatedTextInputs[column.accessorKey] = '';
        onFilter?.(column.accessorKey, '');
      }
    });
    setTextInputValues(updatedTextInputs);
  }, [searchParams]);

  const isDateColumn = (columnKey: string) => {
    const column = columns.find((col) => col.accessorKey === columnKey);
    return column?.header.toLowerCase().includes('date');
  };

  const toggleFilter = (columnKey: string) => {
    setShowFilter((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  const preservePaginationParams = (params: URLSearchParams) => {
    const pageNumber = searchParams.get('pageNumber');
    const pageSize = searchParams.get('pageSize');

    if (pageNumber) params.set('pageNumber', pageNumber);
    if (pageSize) params.set('pageSize', pageSize);

    return params;
  };

  const handleFilterChange = (columnKey: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (value) {
      newSearchParams.set(columnKey, value);
    } else {
      newSearchParams.delete(columnKey);
    }

    preservePaginationParams(newSearchParams);

    setSearchParams(newSearchParams);
    setTextInputValues((prev) => ({
      ...prev,
      [columnKey]: value,
    }));
    onFilter?.(columnKey, value);
  };

  const handleTextInputChange = (columnKey: string, value: string) => {
    setTextInputValues((prev) => ({
      ...prev,
      [columnKey]: value,
    }));
  };

  const handleTextInputKeyDown = (
    columnKey: string,
    event: React.KeyboardEvent,
  ) => {
    if (event.key === 'Enter') {
      handleFilterChange(columnKey, textInputValues[columnKey] || '');
    }
  };
  const clearAllFilters = () => {
    const newSearchParams = new URLSearchParams();
    searchParams.forEach((_, key) => {
      if (key !== 'pageNumber' && key !== 'pageSize') {
        newSearchParams.delete(key);
      }
    });
    preservePaginationParams(newSearchParams);
    setSearchParams(newSearchParams);
    setTextInputValues({});
    console.log(searchParams);

    columns.forEach((column) => {
      if (column.filterable) {
        onFilter?.(column.accessorKey, '');
      }
    });
  };

  const getActiveFilters = () => {
    const filters: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      if (key !== 'pageNumber' && key !== 'pageSize') {
        filters[key] = value;
      }
    });
    return filters;
  };

  const renderFilter = (column: Column) => {
    if (!column.filterable || !showFilter[column.accessorKey]) return null;

    const currentValue = searchParams.get(column.accessorKey) || '';

    switch (column.filterType) {
      case 'select':
        if (!column.filterOptions) return null;
        return (
          <select
            value={currentValue}
            onChange={(e) =>
              handleFilterChange(column.accessorKey, e.target.value)
            }
            className="w-full text-sm border rounded px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white"
          >
            <option value="">All</option>
            {column.filterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );

      case 'date':
        return (
          <div className="space-y-2">
            <div className="flex items-center">
              <input
                type="date"
                value={currentValue}
                onChange={(e) =>
                  handleFilterChange(column.accessorKey, e.target.value)
                }
                className="flex-1 text-sm border rounded px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
                placeholder="Select date"
              />
            </div>
          </div>
        );

      default:
        return (
          <input
            type="text"
            value={textInputValues[column.accessorKey] || ''}
            onChange={(e) =>
              handleTextInputChange(column.accessorKey, e.target.value)
            }
            onKeyDown={(e) => handleTextInputKeyDown(column.accessorKey, e)}
            className="w-full text-sm border rounded px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder={`Filter ${column.header} (press Enter)`}
          />
        );
    }
  };

  const activeFilters = getActiveFilters();

  return (
    <thead className="bg-gray-50">
      <tr>
        {columns.map((column, index) => (
          <th
            key={index}
            className="px-4 sm:px-6 py-3 text-left text-sm font-semibold text-gray-600"
            style={{ minWidth: column.minWidth }}
          >
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-1">
                  <span>{column.header}</span>
                  {isDateColumn(column.accessorKey) && (
                    <button
                      onClick={() => handleSort(column.accessorKey)}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      {sortColumn === column.accessorKey ? (
                        sortDirection === 'asc' ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )
                      ) : (
                        <ChevronUp size={16} className="text-gray-400" />
                      )}
                    </button>
                  )}
                </div>
                {column.filterable && (
                  <button
                    onClick={() => toggleFilter(column.accessorKey)}
                    className={`p-1 rounded hover:bg-gray-100 ${
                      activeFilters[column.accessorKey]
                        ? 'text-blue-600'
                        : 'text-gray-400'
                    }`}
                  >
                    <Filter size={16} />
                  </button>
                )}
              </div>
              <div className="relative">{renderFilter(column)}</div>
            </div>
          </th>
        ))}
        <th className="px-4 sm:px-6 py-3 text-left text-sm font-semibold text-gray-600">
          Actions
        </th>
      </tr>
      {Object.keys(activeFilters).length > 0 && (
        <tr>
          <th
            colSpan={columns.length + (onRowUpdate ? 1 : 0)}
            className="px-4 sm:px-6 py-2"
          >
            <div className="flex justify-end">
              <button
                onClick={clearAllFilters}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Clear all filters
              </button>
            </div>
          </th>
        </tr>
      )}
    </thead>
  );
};
