import moment from 'moment';
import { Column } from 'components/common/table';
import * as React from 'react';
import { getPaymentStatusBackgroundColor } from 'utils/Functions';

export const getTableColumns = (): Column[] => [
  {
    header: 'Transaction Date',
    accessorKey: 'transactionDateTime',
    filterable: true,
    filterType: 'date',
    cell: (value: string) => moment(value).format('Do MMMM YYYY h:mm a'),
  },
  {
    header: 'Enforcement Date',
    accessorKey: 'dateCaptured',
    filterable: true,
    filterType: 'date',
    cell: (value: string) => moment(value).format('Do MMMM YYYY h:mm a'),
  },
  {
    header: 'Ticket Number',
    accessorKey: 'fine.id',
  },
  {
    header: 'Transaction Code',
    accessorKey: 'transactionCode',
    filterable: true,
    filterType: 'text',
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    filterable: true,
    filterType: 'text',
  },
  {
    header: 'Vehicle Registration',
    accessorKey: 'fine.vehicleRegistrationNumber',
  },
  {
    header: 'Status',
    accessorKey: 'fine.status',
    cell: (value: string) => (
      <span
        className={`text-white px-3 py-1 rounded-3xl justify-center flex items-center ${getPaymentStatusBackgroundColor(value)}`}
      >
        {value === 'PAID' ? 'Paid' : 'Not paid'}
      </span>
    ),
  },

  {
    header: 'Payment Method',
    accessorKey: 'chanel',
    filterable: true,
    filterType: 'select',
    filterOptions: [
      { value: 'mobile_money', label: 'Mobile Money' },
      { value: 'noti', label: 'Noti' },
      { value: 'card', label: 'Card' },
      { value: 'teller', label: 'Teller' },
    ],
    cell: (value: string) => (
      <span>
        {value === 'noti'
          ? 'Noti'
          : value === 'card'
            ? 'Card'
            : value === 'teller'
              ? 'Teller'
              : 'Mpesa'}
      </span>
    ),
  },
  {
    header: 'Payer Name',
    accessorKey: 'payerName',
  },
];
