import {
  UseMutationResult,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'hooks/auth/AxiosInstance';
import { AddInfraction } from 'utils/types';

const addInfraction = async (data: AddInfraction) => {
  const response = await axiosInstance.post(
    '/fine/additional_violations',
    data,
  );
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAddInfraction = (): UseMutationResult<any, Error, AddInfraction> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addInfraction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fine'] });
    },
    onError: (error: Error) => {
      console.error('Error adding an infraction:', error.message);
    },
  });
};

export default useAddInfraction;
