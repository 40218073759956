import { FineResponse } from 'utils/types';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'hooks/auth/AxiosInstance';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

type PageType = {
  pageSize: string;
  page: string;
  filters?: Record<string, string>;
  enabled?: boolean;
};

type Options = {
  options: PageType;
};

const fetchData = async ({
  options: { filters = {} },
}: Options): Promise<FineResponse> => {
  try {
    // Convert filters to query parameters
    const queryParams = new URLSearchParams(filters).toString();

    const response: AxiosResponse<FineResponse> = await axiosInstance.get(
      `reports/filter_param${queryParams ? `?${queryParams}` : ''}`,
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('An unknown error occurred');
    }
  }
};

const useGetFines = ({
  options,
}: Options): UseQueryResult<FineResponse, Error> => {
  const { page = '1', pageSize = '100', filters = {}, enabled } = options;

  return useQuery<FineResponse, Error>({
    queryKey: ['fines', page, pageSize, filters],
    queryFn: () => fetchData({ options }),
    refetchOnWindowFocus: false,
    staleTime: 0,
    enabled: enabled,
    refetchInterval: 10000,
  });
};

export default useGetFines;
