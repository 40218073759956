import {
  Home,
  AlertTriangle,
  DollarSign,
  HandCoins,
  ChartNoAxesGantt,
  ClipboardMinus,
} from 'lucide-react';

export const SidebarItems = [
  { title: 'Dashboard', icon: Home, path: '/' },
  {
    title: 'Finance',
    icon: HandCoins,
    children: [
      {
        title: 'Overview',
        path: '/finance/overview',
        icon: ChartNoAxesGantt,
      },

      {
        title: 'Transactions',
        path: '/finance/transactions',
        icon: ClipboardMinus,
      },
    ],
  },
  { title: 'Violations', icon: AlertTriangle, path: '/violations' },
  { title: 'Fines', icon: DollarSign, path: '/fines' },
];
