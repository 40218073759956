import React from 'react';
import { formatDateForInput } from 'utils/Functions';

interface DateRange {
  from: Date;
  to: Date;
}

interface FiltersComponentProps {
  selectedDateRange: DateRange;
  handleDateChange: (type: 'from' | 'to', date: Date) => void; // Expects a Date object
  onSubmit: () => void;
}

const FiltersComponent: React.FC<FiltersComponentProps> = ({
  selectedDateRange,
  handleDateChange,
  onSubmit,
}) => {
  // Helper function to format the date for the datetime-local input

  return (
    <div className="w-full mb-6 bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-6">
        <div className="flex flex-wrap gap-6 items-end">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="from-date"
              className="text-sm font-medium text-gray-700"
            >
              From
            </label>
            <input
              id="from-date"
              type="datetime-local"
              value={formatDateForInput(selectedDateRange.from)}
              onChange={
                (e) => handleDateChange('from', new Date(e.target.value)) // Convert to Date object
              }
              className="w-40 px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm 
                  focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label
              htmlFor="to-date"
              className="text-sm font-medium text-gray-700"
            >
              To
            </label>
            <input
              id="to-date"
              type="datetime-local"
              value={formatDateForInput(selectedDateRange.to)}
              onChange={
                (e) => handleDateChange('to', new Date(e.target.value)) // Convert to Date object
              }
              className="w-40 px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm 
                  focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>

          <button
            onClick={onSubmit}
            className="ml-auto px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md
                hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                transition-colors duration-200"
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiltersComponent;
