// FineViewAuth.tsx - Main component
import React, { useEffect, useState } from 'react';
import NtsaLogo from '../../assets/images/ntsa.svg';
import useFineAuth from 'hooks/fines/useFineAuth';
import FineView from 'pages/Fines/FineVew';
import { useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import useSendOtp from 'hooks/fines/useSendOtp';
import { ValidationError } from 'utils/types';
import VehicleRegistrationForm from 'components/fines/VehicleRegistrationForm';
import OtpVerificationForm from 'components/fines/OtpVerificationForm';

const FineViewAuth: React.FC = () => {
  const { mutateAsync, isPending } = useFineAuth();
  const { mutateAsync: sendOtp, isPending: otpPending } = useSendOtp();
  const params = useParams();
  const [vehicleReg, setVehicleReg] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [validation, setValidation] = useState<ValidationError>({
    hasError: false,
    message: '',
  });
  const [response, setResponse] = useState();
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    localStorage.setItem('authType', 'regular');
    return () => {
      localStorage.removeItem('authType');
    };
  }, []);

  useEffect(() => {
    if (params.ticketNo) {
      localStorage.setItem('ticketNo', params.ticketNo);
    }
  }, [params.ticketNo]);

  useEffect(() => {
    if (!showOtpInput || timeLeft === 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          setCanResend(true);
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [showOtpInput, timeLeft]);

  const resetOtp = () => {
    setOtp(new Array(6).fill(''));
    setTimeLeft(120);
    setCanResend(false);
  };

  const handleResendOtp = async () => {
    if (!canResend) return;

    try {
      const post = {
        ticketNumber: params.ticketNo || '',
        numberPlate: vehicleReg,
      };

      await sendOtp(post);
      resetOtp();
      toast.success('New OTP sent to your registered phone number', {
        position: 'top-right',
        transition: Bounce,
      });
    } catch {
      toast.error('Failed to resend OTP. Please try again.', {
        position: 'top-right',
        transition: Bounce,
      });
    }
  };

  const handleVehicleRegSubmit = async (vehicleRegValue: string) => {
    const post = {
      ticketNumber: params.ticketNo || '',
      numberPlate: vehicleRegValue,
    };

    try {
      await sendOtp(post);
      setShowOtpInput(true);
      toast.success('OTP sent to your registered phone number', {
        position: 'top-right',
        transition: Bounce,
      });
    } catch {
      toast.error('Failed to send OTP. Please try again.', {
        position: 'top-right',
        transition: Bounce,
      });
    }
  };

  const handleOtpSubmit = async (otpValue: string[]) => {
    const post = {
      Id: params.ticketNo || '',
      password: vehicleReg,
      otp: otpValue.join(''),
    };

    try {
      const res = await mutateAsync(post);
      if (res.error) {
        toast.error('Invalid OTP. Please try again.', {
          position: 'top-right',
          transition: Bounce,
        });
      } else {
        setResponse(res.fine);
      }
    } catch {
      toast.error('Invalid OTP. Please try again.', {
        position: 'top-right',
        transition: Bounce,
      });
    }
  };

  if (response) {
    return <FineView fine={response} />;
  }

  return (
    <div className="flex h-screen p-4">
      <div className="w-full flex items-center justify-center p-6">
        <div className="max-w-md w-full space-y-8">
          <img src={NtsaLogo} alt="ntsa" className="h-fit w-fit" />

          {!showOtpInput ? (
            <VehicleRegistrationForm
              vehicleReg={vehicleReg}
              setVehicleReg={setVehicleReg}
              validation={validation}
              setValidation={setValidation}
              onSubmit={handleVehicleRegSubmit}
              isPending={otpPending}
            />
          ) : (
            <OtpVerificationForm
              otp={otp}
              setOtp={setOtp}
              timeLeft={timeLeft}
              canResend={canResend}
              onResendOtp={handleResendOtp}
              onSubmit={handleOtpSubmit}
              isPending={isPending}
              onBack={() => {
                setShowOtpInput(false);
                resetOtp();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FineViewAuth;
